<template>
  <div class="ec-footer">
    <div class="container">
      <div class="col">
        <div class="footer-slot">
          <ECPhones footer/>
        </div>

        <div
          class="footer-slot mail"
          v-if="$store.state.commonContactsData"
        >
          <div class="name">Электронная почта</div>
          <a
            v-if="$store.state.commonContactsData.mail_footer"
            class="link"
            :href="`mailto:${$store.state.commonContactsData.mail_footer}`"
          >
            {{$store.state.commonContactsData.mail_footer}}
          </a>
        </div>
      </div>

      <div class="col">
        <div class="footer-slot">
          <div class="name">Документы</div>
          <a class="link" target="_blank" href="/docs/Politika obrabotki personalnykh dannykh.pdf">Политика конфиденциальности</a>
          <a class="link" target="_blank" href="/docs/Polzovatelskoe soglashenie roecocity ru.pdf">Пользовательское соглашение</a>
          <a class="link" target="_blank" href="/docs/Problema_s_oplatoi.pdf">Действия при проблемах с оплатой</a>
          <a class="link" target="_blank" href="/docs/sber_oplata.pdf">Инструкция по оплате Сбербанк</a>
          <!-- <a class="link" target="_blank" href="/docs/eskbpay.pdf">Инструкция по оплате - ЭСКБ/ТКО</a> -->
        </div>
      </div>

      <div class="col">
        <div
          class="footer-slot"
          v-if="$store.state.commonContactsData"
        >
          <div class="name">Наши социальные сети</div>
          <div class="social-list">
            <a
              target="_blank"
              v-if="$store.state.commonContactsData.social_link_vk"
              :href="$store.state.commonContactsData.social_link_vk"
            >
              <img src="@/assets/images/social-vk-black.svg">
            </a>
            <a
              target="_blank"
              :href="`https://wa.me/${$store.state.commonContactsData.social_link_whatsapp}`"
              v-if="$store.state.commonContactsData.social_link_whatsapp"
            >
              <img src="@/assets/images/social-whatsapp-black.svg">
            </a>
              <a
              target="_blank"
              :href="`tg://resolve?domain=${$store.state.commonContactsData.social_link_telegram}`"
              v-if="$store.state.commonContactsData.social_link_telegram"
            >
              <img src="@/assets/images/social-tg-black.svg">
            </a>
            <a
              target="_blank"
              v-if="$store.state.commonContactsData.social_link_youtube"
              :href="$store.state.commonContactsData.social_link_youtube"
            >
              <img src="@/assets/images/social-youtube-black.svg">
            </a>
          </div>
        </div>
        <div class="footer-slot">
          <div class="name">Дизайн и разработка</div>
          <a target="_blank" href="http://about.qodex.tech/">
            <img src="@/assets/images/qodex-logo.svg">
          </a>
        </div>
        <div class="footer-slot">
          © 2016 - {{currentYear}} <br/>
          Региональный оператор «ЭКО-Сити»
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import ECPhones from '@/components/common/ECPhones.vue'

const currentYear = new Date().getFullYear()
</script>

<style scoped lang="scss">
.ec-footer{
  background: white;
  padding: 36px 0;
}

.container{
  display: flex;
  justify-content: space-between;
  @media (max-width: 740px){
    flex-direction: column;
  }
}

.col{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &:nth-child(3){
    @media (max-width: 1100px){
      max-width: 170px;
    }
    @media (max-width: 740px){
      max-width: none;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      .footer-slot{
        width: 50%;
        &:first-child{
          width: 100%;
        }
      }
    }
  }
}

.footer-slot{
  margin-bottom: 25px;
  &:last-child{
    margin-bottom: 0;
    @media (max-width: 740px){
      margin-bottom: 25px;
    }
  }
}

.name{
  font-size: 14px;
  line-height: 130%;
  color: rgba(22, 22, 22, 0.5);
  margin-bottom: 10px;
}

.link{
  text-decoration: none;
  display: block;
  margin-bottom: 8px;
  &:last-child{
    margin-bottom: 0;
  }
}

.social-list{
  a{
    margin-right: 12px;
  }
}
</style>
