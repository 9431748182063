<template>
  <ECModal
    @close="$emit('close')"
    title="Тема обращения"
  >
      <form id="theme-support" class="content" @submit.prevent="">
        <div class="form-item">
          <ECButton
            label="Заявка на дополнительный вывоз"
            view="grey"
            @click="$emit('openModal', 1)"
          />
        </div>
        <div class="form-item">
          <ECButton
            label="Другая тема"
            view="grey"
            @click="$emit('openModal', 2)"
          />
        </div>
      </form>
  </ECModal>
</template>

<script setup>

</script>

<style scoped lang="scss">
:deep(.ec-button){
  font-size: 14px !important;
  justify-content: flex-start !important;
}
.form-item{
  margin-bottom: 10px;
  &:last-child{
    margin-bottom: 0;
  }
}
</style>
