<template>
  <div class="ec-header">
    <div class="ec-header container">

      <router-link to="/">
        <img src="@/assets/images/logo.svg" alt="">
      </router-link>

      <nav>
        <router-link to="/news">Новости</router-link>
        <router-link to="/about">О компании</router-link>
        <router-link to="/documents">Документы</router-link>
        <router-link to="/contacts">Контакты</router-link>
        <router-link to="/containers-pickup-schedule">Контейнерные площадки</router-link>
      </nav>

      <div class="right-part">
        <div class="call" ref="target">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.24834 3.49671L5.2489 3.49741L5.24834 3.49671ZM5.28418 3.55298L6.95462 6.1851C6.75277 6.37461 6.53259 6.56233 6.29563 6.76435C6.09197 6.93798 5.87591 7.12219 5.64844 7.32719C5.08463 7.83528 4.97573 8.65245 5.34062 9.2884C7.27016 12.6513 9.90192 14.1815 11.0795 14.7309C11.7889 15.0619 12.5597 14.8007 12.9888 14.2598L14.1448 12.8027C14.1448 12.8027 14.1457 12.8019 14.1475 12.8016C14.1492 12.8013 14.1524 12.8016 14.1524 12.8016C14.1524 12.8016 14.1549 12.8022 14.1573 12.8036C14.8309 13.2153 15.7706 13.7748 16.6338 14.2485C16.4788 15.1753 16.1227 15.7153 15.73 16.0454C15.3177 16.392 14.7845 16.5744 14.1898 16.6403C13.0024 16.7718 11.797 16.415 11.5296 16.3281C11.2346 16.1788 9.61721 15.3357 7.88924 13.9694C6.10744 12.5606 4.32078 10.683 3.63747 8.52963C3.13808 6.95587 3.32639 5.84842 3.71889 5.08452C4.09833 4.34605 4.71236 3.84483 5.28418 3.55298ZM4.6799 1.99519C5.46711 1.62544 6.2811 2.01095 6.66025 2.60838L8.40778 5.36196C8.76107 5.91863 8.78528 6.73241 8.21094 7.28988C7.96479 7.52879 7.62234 7.82243 7.30366 8.09569C7.12873 8.24568 6.96097 8.38953 6.82024 8.51481C8.4809 11.3784 10.7011 12.7033 11.7109 13.1878L12.835 11.7708C13.3657 11.1018 14.3106 10.9454 15.0303 11.3853C15.6959 11.7921 16.6162 12.3397 17.4502 12.797C18.0064 13.1019 18.4043 13.7377 18.2916 14.4555C18.0891 15.7446 17.5602 16.6836 16.8066 17.3172C16.0656 17.9401 15.1829 18.2044 14.3742 18.294C12.7837 18.4701 11.2624 17.9932 10.9846 17.9011C10.9143 17.8777 10.8559 17.8531 10.7932 17.8215C10.4949 17.6715 8.73736 16.764 6.85148 15.2729C4.98522 13.7973 2.88052 11.6627 2.04551 9.03131C1.43216 7.09839 1.6136 5.53113 2.23274 4.32617C2.84102 3.14232 3.8261 2.39621 4.6799 1.99519Z" fill="black"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2589 1.74203C11.8398 1.5504 11.3442 1.73359 11.1519 2.15119C10.9596 2.56879 11.1435 3.06267 11.5625 3.2543C13.5012 4.14085 15.7201 5.80762 16.7341 8.10765C16.9195 8.52831 17.412 8.71951 17.8342 8.5347C18.2563 8.34989 18.4481 7.85906 18.2627 7.43839C17.0241 4.6289 14.4062 2.72399 12.2589 1.74203ZM11.6408 4.69354C11.2284 4.48806 10.7269 4.65463 10.5207 5.0656C10.3146 5.47656 10.4817 5.97629 10.8941 6.18177C11.6786 6.57265 12.2215 6.941 12.6598 7.37784C13.0982 7.81468 13.4678 8.35573 13.8601 9.13749C14.0662 9.54846 14.5677 9.71503 14.9801 9.50955C15.3925 9.30407 15.5596 8.80434 15.3534 8.39338C14.9108 7.51125 14.4456 6.80438 13.8405 6.20129C13.2353 5.5982 12.526 5.1346 11.6408 4.69354Z" fill="black"/>
          </svg>
          <span @click="showPhones = !showPhones">Позвонить</span>

          <div
            class="dropdown"
            :class="{show: showPhones}"
          >
            <ECPhones header />
          </div>
        </div>

        <div class="separator"/>

        <div
          class="acc-handler"
          @click="$store.state.user.token ? $store.dispatch('logout') : isShowModalLogin = true"
        >
          {{ $store.state.user.token ? 'Выйти' : 'Войти' }}
        </div>
      </div>

      <ECHeaderAdaptiveBlock @login="isShowModalLogin = true"/>
    </div>
  </div>


  <transition name="default">
    <ECModalLogin
      v-if="isShowModalLogin"
      @close="isShowModalLogin  = false"
      :currentSwitcherItem="currentSwitcherItem"
      @changeCurrentSwitcherItem="changeCurrentSwitcherItem"
      @updateBill="tempBill = $event"
      @updateLastname="tempInn = $event"
      @updateInn="tempInn = $event"
      @showModalNoEmail="isShowModalNoEmail = true"
      @setTempEmailAndOpenConfirmEmail="setTempEmailAndOpenConfirmEmail"
      @setTempBillAndNameAndOpenUpdate="setTempBillAndNameAndOpenUpdate"
      @closeAndOpenRecovery="closeAndOpenRecovery"
    />
  </transition>

  <transition name="default">
    <ECModalUpdated
      v-if="isShowModalUpdated"
      @close="isShowModalUpdated = false"
      :inn="tempInn"
      :bill="tempBill"
      :name="tempLastname"
      :loginType="currentSwitcherItem"
      @setTempPhoneAndOpenConfirmPhone="setTempPhoneAndOpenConfirmSms"
    />
  </transition>

  <transition name="default">
    <ECModalConfirmPhone
      v-if="isShowModalConfirmPhone"
      @close="isShowModalConfirmPhone = false"
      :phone="tempPhone"
      :inn="tempInn"
      :bill="tempBill"
      :name="tempLastname"
      :loginType="currentSwitcherItem"
      @successCode="isShowModalCreatePassword = true"
    />
  </transition>

  <transition name="default">
    <ECModalCreatePassword
      v-if="isShowModalCreatePassword"
      @close="isShowModalCreatePassword = false"
      :phone="tempPhone"
    />
  </transition>

  <transition name="default">
    <ECModalNoEmail
      v-if="isShowModalNoEmail"
      @close="isShowModalNoEmail = false"
      @setTempPhoneAndOpenConfirmSms="setTempPhoneAndOpenConfirmSms"
    />
  </transition>

  <transition name="default">
    <ECModalConfirmEmail
      v-if="isShowModalConfirmEmail"
      @close="isShowModalConfirmEmail = false"
      :email="tempEmail"
      :inn="tempInn"
      @successful="currentSwitcherItem === 2 ? isShowModalUpdated = true : isShowModalConfirmPhone = true"
    />
  </transition>

  <transition name="default">
    <ECModalPaymentStatus
      v-if="isShowModalPaymentStatus"
      @close="isShowModalPaymentStatus = false"
    />
  </transition>

  <transition name="default">
    <ECModalAutoPaymentStatus
      v-if="isShowModalAutoPaymentStatus"
      @close="isShowModalAutoPaymentStatus = false"
    />
  </transition>

  <transition name="default">
    <ECModalPasswordRecovery
      v-if="isShowEodalPasswordRecovery"
      @close="isShowEodalPasswordRecovery = false"
      @successful="setTempPhoneAndOpenConfirmSmsThenRecovery"
    />
  </transition>

  <transition name="default">
    <ECModalConfirmPhone
      v-if="isShowModalConfirmPhoneThenRecovery"
      @close="isShowModalConfirmPhoneThenRecovery = false"
      :phone="tempPhone"
      :loginType="3"
      @successCode="isShowModalCreatePasswordThenRecovery = true"
    />
  </transition>

  <transition name="default">
    <ECModalCreatePassword
      v-if="isShowModalCreatePasswordThenRecovery"
      @close="isShowModalCreatePasswordThenRecovery = false"
      :phone="tempPhone"
      :type="'forgot'"
    />
  </transition>

  <transition name="left">
    <ECNotification
      v-if="notificationText"
      :text="notificationText"
      :status="notificationStatus"
    />
  </transition>
</template>

<script setup>
import { ref, onMounted, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router'
import { onClickOutside } from '@vueuse/core'
import useApi from '@/services/useApi'
import ECModalLogin from '@/components/modals/ECModalLogin.vue'
import ECModalUpdated from '@/components/modals/ECModalUpdated.vue'
import ECModalConfirmPhone from '@/components/modals/ECModalConfirmPhone.vue'
import ECModalCreatePassword from '@/components/modals/ECModalCreatePassword.vue'
import ECModalNoEmail from '@/components/modals/ECModalNoEmail.vue'
import ECModalConfirmEmail from '@/components/modals/ECModalConfirmEmail.vue'
import ECModalPaymentStatus from '@/components/modals/ECModalPaymentStatus.vue'
import ECModalAutoPaymentStatus from '@/components/modals/ECModalAutoPaymentStatus.vue'
import ECHeaderAdaptiveBlock from './ECHeaderAdaptiveBlock.vue'
import ECModalPasswordRecovery from '@/components/modals/ECModalPasswordRecovery.vue'
import ECPhones from '@/components/common/ECPhones.vue'
import ECNotification from '@/components/ui/ECNotification.vue'

const route = useRoute()
const router = useRouter()

const target = ref(null)
const showPhones = ref(false)
onClickOutside(target, (event) => showPhones.value = false)


const isShowModalLogin = ref(false)
const isShowModalUpdated = ref(false)
const isShowModalConfirmPhone = ref(false)
const isShowModalCreatePassword = ref(false)
const isShowModalNoEmail = ref(false)
const isShowModalConfirmEmail = ref(false)
const isShowModalPaymentStatus = ref(false)
const isShowModalAutoPaymentStatus = ref(false)
const isShowEodalPasswordRecovery = ref(false)
const isShowModalConfirmPhoneThenRecovery = ref(false)
const isShowModalCreatePasswordThenRecovery = ref(false)

const currentSwitcherItem = ref(0)

const tempBill = ref('')
const tempInn = ref('')
const tempLastname = ref('')
const tempPhone = ref('')
const tempEmail = ref('')

const changeCurrentSwitcherItem = e => {
  currentSwitcherItem.value = e
}

const setTempPhoneAndOpenConfirmSms = e => {
  tempPhone.value = e
  isShowModalConfirmPhone.value = true
}

const setTempEmailAndOpenConfirmEmail = e => {
  tempEmail.value = e
  isShowModalConfirmEmail.value = true
}

const setTempBillAndNameAndOpenUpdate = (name, bill) => {
  tempBill.value = bill
  tempLastname.value = name
  isShowModalUpdated.value = true
}

const setTempPhoneAndOpenConfirmSmsThenRecovery = e => {
  tempPhone.value = e
  isShowModalConfirmPhoneThenRecovery.value = true
}

const closeAndOpenRecovery = () => {
  isShowModalLogin.value = false
  isShowEodalPasswordRecovery.value = true
}

const notificationText = ref('')
const notificationStatus = ref(true)

const showNotification = (text, status = true) => {
  notificationText.value = text
  notificationStatus.value = status
  setTimeout(()=> {
    router.push({query: null})
    notificationText.value = ''
  }, 4000)
}

watchEffect(() => {
  if(route.query.autosave_status){
    showNotification('Данные успешно изменены!')
  }
  if(route.query.payment_status){
    isShowModalPaymentStatus.value = true
  }
})


</script>

<style scoped lang="scss">
.ec-header{
  height: 74px;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media (max-width: 1100px){
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;
    border-bottom: 1px solid $ec-grey;
  }
}

nav{
  a{
    font-size: 16px;
    text-decoration: none;
    margin-right: 24px;
    transition: ease .3s;
    &:last-child{
      margin-right: 0px;
    }
  }
  @media (max-width: 1100px){
    display: none;
  }
}

.right-part{
  display: flex;
  align-items: center;
  .call{
    display: flex;
    align-items: center;
    position: relative;
    padding-bottom: 11px;
    margin-top: 11px;
    cursor: pointer;
    span{
      font-size: 16px;
      user-select: none;
    }
    svg{
      margin-right: 10px;
    }
    .dropdown{
      opacity: 0;
      visibility: hidden;
      position: absolute;
      right: 0px;
      top: 32px;
      background: white;
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
      border-radius: 22px;
      padding: 8px;
      transition: ease .3s;
    }
    .show{
      opacity: 1;
      visibility: visible;
    }
  }
  .separator{
    width: 2px;
    height: 16px;
    background: rgba(169, 169, 169, 0.4);
    border-radius: 2px;
    margin: 0 24px;
  }
  .acc-handler{
    font-size: 18px;
    line-height: 22px;
    color: #57BA5E;
    cursor: pointer;
    transition: ease .3s;
    &:hover{
      color: #64CC6C;
    }
  }
  @media (max-width: 1100px){
    display: none;
  }
}
</style>
