<template>
  <ECModal
    @close="$emit('close')"
    title="Вход в личный кабинет"
  >
    <form id="confirm-email" class="content" @submit.prevent="">
      <div class="modal-info">Код отправлен на почту <br> <b>{{email}}</b></div>

      <div class="form-item">
        <label>Код из письма</label>
        <ECInput
          v-model="code"
          placeholder=""
          type="text"
          masked="code"
          :error="error"
        />
      </div>

      <div class="form-item">
        <ECButton
          label="Войти"
          view="primary"
          :disabled="!validate"
          @click="sendForm"
        />
      </div>
    </form>
  </ECModal>
</template>

<script setup>
import { ref, computed, defineEmits, defineProps } from 'vue'
import useApi from '@/services/useApi'

const props = defineProps({
  email: {
    type: String,
    required: true,
  },
  inn: {
    type: String,
    required: true,
  }
})
const emit = defineEmits(['close', 'successful'])

const code = ref('')
const error = ref('')

const validate = computed(() => code.value.length === 4 )

const sendForm = async() => {
  error.value = ''

  const req = await useApi('post', 'users/check_by_inn_mail', {
    inn: props.inn,
    code: code.value
  })
  if(req.error){
    error.value = req.error
  }
  if(req.status === 'successful'){
    emit('close')
    emit('successful')
  }
}
</script>

<style scoped lang="scss"></style>
