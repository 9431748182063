<template>
  <div class="ec-textarea">
    <textarea
      :disabled="disabled"
      :placeholder="placeholder"
      :value="modelValue"
      @input="onInput"
      autocomplete="off"
    />
  </div>
</template>

<script setup>
import { ref, defineEmits, defineProps, computed, onMounted } from 'vue'
import { maska as vMaska } from 'maska'

const props = defineProps({
  modelValue: {
    type: String,
    default: null,
  },
  placeholder: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  }
})
const emit = defineEmits(['update:modelValue'])

const onInput = (e) => {
  emit('update:modelValue', e.target.value)
}
</script>

<style scoped lang="scss">
.ec-textarea{
  textarea {
    background: $ec-grey;
    border-radius: 16px;
    height: 60px;
    padding: 8px 18px;
    resize: none;
    border: none;
    width: 100%;
    &::placeholder {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: rgba(22, 22, 22, 0.3);
    }
  }
}
</style>
