<template>
  <ECModal
    @close="$emit('close')"
    title="Добавление лицевого счета"
  >
    <form id="add-bill" class="content" @submit.prevent="">

      <div class="modal-info">Введите номер лицевого счета</div>

      <div class="form-item">
        <ECInput
          v-model="number"
          placeholder=""
          type="text"
          masked="bill"
          :error="error"
          green
          focus
        />
      </div>
      <div class="form-item">
        <ECButton
          label="Сохранить"
          view="primary"
          :disabled="!validate"
          @click="sendForm"
        />
      </div>

      <!-- <div class="above green-link">
        <div>Если не знаете свои лицевой счет,</div>
        <div @click="$emit('closeAndOpenSearchBill')">воспользуйтесь поиском</div>
      </div> -->
    </form>
  </ECModal>
</template>

<script setup>
import { ref, computed, defineEmits, onMounted } from 'vue'
import useApi from '@/services/useApi'
import store from '@/store'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()

const emit = defineEmits(['close', 'successful', 'closeAndOpenSearchBill'])

const number = ref('')
const error = ref('')

const validate = computed(() => number.value.length === 12 )

const sendForm = async() => {
  store.dispatch('setGlobalPreloader', true)

  error.value = ''
  const req = await useApi('post', 'bills', {
    bill_number: number.value
  })
  if(req.error){
    error.value = req.error
    store.dispatch('setGlobalPreloader', false)
  }
  if(req.status === 'successful'){
    emit('close')
    emit('successful', number.value)
    await setTimeout(()=> store.dispatch('setGlobalPreloader', false), 1500)
  }
}

onMounted(()=> {
  setTimeout(()=> {
    if(route.query.bill){
      number.value = route.query.bill
      setTimeout(()=> router.push({query: null}), 0)
    }
  })
})
</script>

<style scoped lang="scss">
.above{
  margin-top: 20px;
  margin-bottom: 20px;
  div{
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #57BA5E;
    cursor: pointer;
    &:first-child{
      color: rgba(#161616, .5);
    }
  }
}
</style>
