<template>
  <ECModal
    @close="$emit('close')"
    title="Экспресс-оплата услуг"
  >
    <form id="express-payment" class="content" @submit.prevent="">

      <svg @click="stepOne = true" v-if="!stepOne" class="step-back" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="12" fill="#424242"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M19 12C19 11.4477 18.5523 11 18 11L18 12L18 13C18.5523 13 19 12.5523 19 12ZM8.24536 11L18 11L18 12L18 13L8.24536 13L11.2428 16.3305C11.2428 16.3305 11.2433 16.331 10.5 17L11.2428 16.3305C11.6123 16.741 11.5795 17.3738 11.169 17.7433C10.7585 18.1127 10.1262 18.0795 9.75671 17.669L5.25671 12.669C4.91443 12.2887 4.91443 11.7113 5.25671 11.331L9.75621 6.33158C10.1257 5.92107 10.7585 5.88724 11.169 6.2567C11.5795 6.62616 11.6128 7.25845 11.2433 7.66896L10.5 6.99999C11.2433 7.66896 11.2433 7.66893 11.2433 7.66896L8.24536 11Z" fill="white"/>
      </svg>

      <div class="modal-info">{{stepOne ? 'Введите лицевой счет' : 'Рекомендовано к оплате'}}</div>

      <div v-if="stepOne">
        <ECInput
          v-model="bill"
          masked="bill"
          green
          :error="error"
        />
        <br/>
      </div>

      <div v-else>
        <ECInput
          placeholder=""
          v-model="summ"
          type="number"
          green
          price
        />

        <br/>

        <!-- <div class="form-item">
          <label>Держатель лицевого счета</label>
          <ECInput v-model="name" :disabled="true"/>
        </div> -->

        <div class="form-item">
          <label>Номер лицевого счета</label>
          <ECInput v-model="bill" :disabled="true"/>
        </div>

        <!-- <div class="form-item">
          <ECButtonYandexPay
            :disabled="false"
            @pay="handleForm({yandexPay: true})"
          />
        </div> -->
      </div>

      <div class="form-item">
        <ECButton
          @click="handleForm"
          :label="stepOne ? 'Далее' : 'Оплатить'"
          :disabled="!validate"
        />
      </div>

      <ECButtonYandexPay
        v-if="!stepOne"
        :disabled="false"
        @pay="handleForm({yandexPay: true})"
      />

      <div v-if="stepOne" class="above">
        <div @click="$emit('closeAndOpenLogin')">Войдите в личный кабинет,</div>
        <div>чтобы управлять счетом</div>
      </div>

      <!-- <div v-if="stepOne && !bill.length" class="above revers">
        <div>Если не знаете свои лицевой счет,</div>
        <div @click="$emit('closeAndOpenSearchBill')">воспользуйтесь поиском</div>
      </div> -->
    </form>
  </ECModal>
</template>

<script setup>
import { defineEmits, ref, computed, onMounted } from 'vue'
import useApi from '@/services/useApi'
import ECButtonYandexPay from '@/components/ui/ECButtonYandexPay.vue'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()

const emit = defineEmits(['closeAndOpenLogin', 'closeAndOpenSearchBill', 'close'])

const summ = ref('')
// const name = ref('')
const bill = ref('')
const error = ref('')
const stepOne = ref(true)

const validate = computed(()=> {
  if(stepOne.value){
    return bill.value.length === 12
  } else {
    return (summ.value[0] && summ.value[0] !== '0')
  }
})

const handleForm = async(type) => {
  error.value = ''

  if(stepOne.value){
    const req = await useApi('get', `bills/pay-info/${bill.value}`)
    if(req.error){
      error.value = req.error
      return
    }
    if(req.status = 'successful'){
      stepOne.value = false
      // name.value = req.name

      if(req.money){
        summ.value = String(req.money)
      }
    }
  } else {
    const req = await useApi('post', type.yandexPay ? 'payment/yapay/create' : 'payment/sber/non-auth', {
      amount: +summ.value,
      bill_number: bill.value
    })
    if(req.status === 'successful'){
      window.location.assign(req.url)
    }
  }
}

onMounted(()=> {
  setTimeout(()=> {
    if(route.query.bill){
      bill.value = route.query.bill
      setTimeout(()=> router.push({query: null}), 0)
    }
  })
})
</script>

<style scoped lang="scss">
.content{
  position: relative;
  .step-back{
    position: absolute;
    left: 0;
    top: -77px;
    transition: ease .3s;
    cursor: pointer;
    &:hover{
      opacity: 0.8;
    }
  }
}
.above{
  margin-top: 20px;
  margin-bottom: 20px;
  div{
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: rgba(#161616, .5);
    &:first-child{
      color: #57BA5E;
      cursor: pointer;
    }
  }
  &.revers{
    div{
      color: #57BA5E;
      cursor: pointer;
      &:first-child{
        color: rgba(#161616, .5);
      }
    }
  }
}
</style>
