<template>
  <ECModal
    @close="$emit('close')"
    title="Вход в личный кабинет"
  >
    <form id="login" class="content" @submit.prevent="">
      <div
        v-if="!hideSwitcher"
        class="switcher active"
        :class="`active-${currentSwitcherItem}`"
      >
        <div
          class="switcher__item"
          :class="{active: idx === currentSwitcherItem}"
          v-for="(item, idx) in switcherList"
          :key="idx"
          @click="$emit('changeCurrentSwitcherItem', idx)"
        >
          {{ item }}
        </div>
      </div>

      <div class="modal-info" v-if="currentSwitcherItem !== 0">Вход для {{ currentSwitcherItem ===1 ? 'физических' : 'юридических' }} лиц</div>

      <div class="type-content">

        <div v-if="currentSwitcherItem === 0" class="phone">
          <div class="form-item">
            <label>Телефон</label>
            <ECInput
              placeholder="+7"
              masked="tel"
              v-model="phone"
            />
          </div>
          <div class="form-item">
            <label>Пароль</label>
            <ECInput
              v-model="password"
              placeholder="Введите пароль"
              :type="typeOfPasswordInput"
              :show-password="true"
              @switch-show-pass="typeOfPasswordInput === 'password' ? typeOfPasswordInput = 'text' : typeOfPasswordInput = 'password'"
            />
          </div>
        </div>

        <div v-if="currentSwitcherItem === 1" class="bill">
          <div class="form-item">
            <label>Номер лицевого счета</label>
            <ECInput
              placeholder="Введите лицевой счет физ.лица"
              v-model="bill"
              masked="bill"
            />
          </div>
          <div class="form-item">
            <label>Фамилия</label>
            <ECInput
              v-model="lastname"
            />
          </div>
        </div>

        <div v-if="currentSwitcherItem === 2" class="inn">
          <div class="form-item">
            <label>ИНН</label>
            <ECInput
              v-model="inn"
              masked="inn"
            />
          </div>
        </div>

        <transition name="bottom">
          <div v-if="error" class="error">{{ error }}</div>
        </transition>

        <div class="form-item form-item--checkbox">
          <ECCheckbox
            label="Оставаться в системе"
            :checked="rememberCheckbox"
            @clicked="rememberCheckbox = !rememberCheckbox"
          />
        </div>
        <div class="form-item form-item--login">
          <ECButton
            label="Войти"
            view="primary"
            :disabled="!validate"
            @click="login"
          />
        </div>
        <div v-if="currentSwitcherItem === 0" class="form-item">
          <ECButton
            label="Забыли данные для входа?"
            view="grey"
            @click="$emit('closeAndOpenRecovery')"
          />
        </div>
      </div>
    </form>
  </ECModal>
</template>

<script>
import { defineComponent, PropType, ref, computed, watch, toRef, watchEffect, onMounted } from 'vue'
import useApi from '@/services/useApi'
import store from '@/store'
import formattedPhone from '@/helpers/formattedPhone.js'
import { useRouter, useRoute } from 'vue-router'

export default defineComponent({
  name: "ECModalLogin",

  emits: [
    'close',
    'changeCurrentSwitcherItem',
    'updateBill',
    'updateLastname',
    'updateInn',
    'showModalNoEmail',
    'setTempEmailAndOpenConfirmEmail',
    'setTempBillAndNameAndOpenUpdate',
    'closeAndOpenRecovery'
  ],

  props: {
    currentSwitcherItem: {
      type: Number,
      required: true,
    },
    hideSwitcher: {
      type: Boolean,
      default: false,
    }
  },

  setup(props, {emit}){
    const router = useRouter()
    const route = useRoute()

    const currentSwitcherItem = toRef(props, 'currentSwitcherItem')

    const switcherList = ref(['Телефон', 'Лицевой счёт', 'ИНН'])

    const phone = ref('')
    const password = ref('')
    const typeOfPasswordInput = ref('password')

    const bill = ref('')
    const lastname = ref('')
    const inn = ref('')

    watchEffect(() => emit('updateBill', bill.value))
    watchEffect(() => emit('updateLastname', lastname.value))
    watchEffect(() => emit('updateInn', inn.value))

    const error = ref('')

    const rememberCheckbox = ref(true)

    // remove acc
    const deleteTest = async() => {
      await useApi('delete', 'users/delete_test_user')
    }

    watch(
      () => currentSwitcherItem.value,
      () => {
        error.value = ''
        inn.value = ''
        lastname.value = ''
        bill.value = ''
        password.value = ''
        // phone.value = ''
      }
    )

    const validate = computed(() =>
      (currentSwitcherItem.value === 2 && inn.value.length === 10 || inn.value.length === 12) ||
      (currentSwitcherItem.value === 1 && bill.value.length === 12 && lastname.value.length >= 2) ||
      (currentSwitcherItem.value === 0 && phone.value.length === 16 && password.value.length >= 3)
    )

    const login = async() => {
      error.value = ''

      if(currentSwitcherItem.value === 0) {
        const req = await useApi('post', 'users/auth_by_phone', {
          phone: formattedPhone(phone.value),
          password: password.value
        })
        if(req.error){
          error.value = req.error
        }
        if(req.token){
          emit('close')
          store.dispatch('login', req)
          if(window.innerWidth < 740){
            router.push('/account')
          }
        }
      }

      if(currentSwitcherItem.value === 1){
        const req = await useApi('post', 'users/validate_by_personal_bill', {
          name: lastname.value,
          personal_bill: bill.value
        })
        if(req.error){
          error.value = req.error
        }
        if(req.status === 'successful'){
          emit('setTempBillAndNameAndOpenUpdate', lastname.value, bill.value)
          emit('close')
        }
      }

      if(currentSwitcherItem.value === 2){
        const req = await useApi('post', 'users/registr_by_inn', {
          inn: inn.value
        })
        if(req.error){
          emit('close')
          emit('showModalNoEmail')
        }
        if(req.status === 'successful'){
          emit('close')
          emit('setTempEmailAndOpenConfirmEmail', req.email)
        }
      }
    }

    onMounted(()=> {
      if(route.query.tel){
        phone.value = route.query.tel
      }
    })

    return {
      currentSwitcherItem,
      switcherList,

      rememberCheckbox,
      phone,
      password,
      typeOfPasswordInput,

      bill,
      lastname,

      inn,

      validate,

      login,

      formattedPhone,

      error,

      deleteTest,
    }
  }
});
</script>

<style scoped lang="scss">
.face{
  font-size: 16px;
  margin-bottom: 20px;
  text-align: center;
  color: rgba($ford-medium-dark, .6);
}

.switcher{
  display: flex;
  justify-content: space-between;
  background: $ec-grey;
  border-radius: 14px;
  padding: 6px;
  margin-bottom: 20px;
  &__item{
    isolation: isolate;
    text-align: center;
    transition: ease .3s;
    padding: 6px 10px;
    cursor: pointer;
    &:nth-child(1){
      width: 100px;
    }
    &:nth-child(2){
      width: 120px;
    }
    &:nth-child(3){
      width: 77px;
    }
    &.active{
      color: white;
    }
  }
  &.active{
    position: relative;
    &:before{
      content: '';
      transition: ease .3s;
      top: 5px;
      left: 5px;
      position: absolute;
      width: 100px;
      height: 36px;
      background: $ec-green;
      border-radius: 10px;
    }
    &-1{
      &:before{
        left: 107px;
        width: 120px;
      }
    }
    &-2{
      &:before{
        left: 228px;
        width: 77px;
      }
    }
  }
}

.error{
  margin: 0 auto;
  margin-bottom: 10px;
  color: #FF7A7A;
  text-align: center;
}

.form-item{
  &:last-child{
    margin-bottom: 0;
  }
}

.form-item--checkbox{
  margin-top: 20px;
}
.form-item--login{
  margin-bottom: 10px;
}
</style>
