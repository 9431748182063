<template>
  <div class="dadata-search">
    <transition name="default">
      <div
        class="dadata"
        :style="{'max-height': maxHeight}"
        v-if="dadataSuggestionsArr.length && isShowDaDataMenu"
      >
        <div class="dadata-item"
          v-for="item in dadataSuggestionsArr"
          :key="item.inn"
          @click="setValue(item)"
        >
          <template v-if="type === 'inn'">
            <span>{{item.label}}</span>
            <span>ИНН {{item.inn}} <template v-if="item.kpp">/ КПП {{item.kpp}}</template></span>
          </template>
          <template v-else>
            <span>{{item.value}}</span>
          </template>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref, watchEffect, defineEmits, defineProps } from 'vue'

const props = defineProps({
  type: {
    type: String,
    default: ()=> 'address'
  },
  search: {
    type: String,
    default: ()=> ''
  },
  maxHeight: {
    type: String,
    default: 'auto'
  }
})

const emit = defineEmits(['setDadata'])

const dadataSuggestionsArr = ref([])
const isShowDaDataMenu = ref(false)
const flag = ref(true)

const tempVal = ref('')


watchEffect(async() => {
  if((tempVal.value !== props.search && props.search.length >= 3 && flag.value && props.type === 'address') || (tempVal.value !== props.search && props.type === 'inn' && flag.value && (props.search.length === 10 || props.search.length === 12))){

    const url = `https://suggestions.dadata.ru/suggestions/api/4_1/rs/${props.type === 'inn' ? 'findById/party' : 'suggest/address'}`

    const reqBodyInn = {
      query: props.search,
      restrict_value: true,
      locations: [{kladr_id: "0200000000000"}],
      locations_boost: [{kladr_id: "7800000000000"}],
      count: 10
    }

    const options = {
      method: "POST",
      mode: "cors",
      headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": "Token " + process.env.VUE_APP_DADATA_TOKEN
      },
      body: JSON.stringify(props.type !== 'inn' ? reqBodyInn : {query: props.search})
    }
    const data = await fetch(url, options)
    const dd = await data.json()

    if(props.type === 'inn'){
      dadataSuggestionsArr.value = dd.suggestions.map(item => {
        return {
          label: item.value,
          inn: item.data.inn,
          kpp: item.data.kpp || '',
        }
      })
    } else {
      dadataSuggestionsArr.value = dd.suggestions
    }
    isShowDaDataMenu.value = true
  } else {
    dadataSuggestionsArr.value = []
  }
  flag.value = true
})

const setValue = val => {
  emit('setDadata', val)
  isShowDaDataMenu.value = false
  tempVal.value = props.type === 'inn' ? val.inn : val.value
  flag.value = false
}
</script>

<style scoped lang="scss">
.dadata-search{
  position: relative;
}
.dadata{
  position: absolute;
  left: 0;
  top: 7px;
  right: 0;
  background: #FAFAFA;
  border-radius: 22px;
  z-index: 2;
  padding: 16px 8px;
  display: flex;
  flex-direction: column;
  // max-height: 322px;
  overflow: auto;
  box-shadow: 0px 0px 30px rgb(0 0 0 / 10%);
  .dadata-item{
    padding: 8px 10px;
    transition: ease .3s;
    cursor: pointer;
    border-radius: 14px;
    &:hover{
      background: rgba(#555555, .1);
    }
    span{
      display: block;
      opacity: .5;

      &:first-child{
        opacity: 1;
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
      }
    }
  }
}
</style>
