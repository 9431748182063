<template>
  <div class="bills">
    <ECSwitcher
      :list="switcherList"
      :active="currentSwitcherItem"
      @change="currentSwitcherItem = $event"
    />

    <ECButton
      label="Добавить лицевой счет"
      :view="bills ? 'white' : 'primary'"
      @click="isShowModalAddBill = true"
    />

    <div class="content">
      <transition name="quick-hide">
        <div  class="bills__empty" v-if="!filteredBills.length && animateTime && !preloader">
          <h4>Тут у вас пока нет лицевых счетов</h4>
          <p>Вы всегда можете добавить лицевой счет {{currentSwitcherItem === 0 ? 'физического лица' : 'юридического лица'}} , нажав на кнопку “Добавить лицевой счет”</p>
        </div>
      </transition>

      <transition name="quick-hide">
        <div v-if="filteredBills.length && bills.length && !preloader" class="bills-list">
          <ECBill
            v-for="(bill, idx) in filteredBills"
            :key="idx"
            :bill="bill"
            @updateBillsList="getBills"
          />
        </div>
      </transition>


      <transition name="quick-hide">
        <img
          v-if="preloader"
          class="preloader preloader-animation"
          src="@/assets/images/preloader-bill.svg"
        />
      </transition>
    </div>
  </div>

  <transition name="default">
    <ECModalAddBill
      v-if="isShowModalAddBill"
      @close="isShowModalAddBill = false"
      @successful="refreshBillsList"
      @closeAndOpenSearchBill="closeAndOpenSearchBill"
    />
  </transition>

  <transition name="default">
    <ECModalSearchBillByAddress
      v-if="isShowECModalSearchBillByAddress"
      @close="isShowECModalSearchBillByAddress = false"
      @closeAndOpenRoot="closeAndOpenRoot"
    />
  </transition>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import ECModalAddBill from '@/components/modals/ECModalAddBill'
import ECSwitcher from '@/components/ECSwitcher'
import ECBill from '@/components/ECBill'
import useApi from '@/services/useApi'
import store from '@/store'
import ECModalSearchBillByAddress from '@/components/modals/ECModalSearchBillByAddress.vue'

const isShowModalAddBill = ref(false)
const bills = ref([])
const preloader = ref(true)
const animateTime = ref(false)

const isShowECModalSearchBillByAddress = ref(false)

onMounted(async()=> {
  await getBills()
  await setTimeout(()=> animateTime.value = true, 800)
})

const getBills = async() => {
  if(store.state.user.token){
    const req = await useApi('get', 'bills')
    preloader.value = false

    await setTimeout(()=> {
      if(req.bills){
        bills.value = req.bills
      }
    },800)
    await setTimeout(()=> store.dispatch('setGlobalPreloader', false), 1500)
  }
}

const refreshBillsList = e => {
  getBills()
  currentSwitcherItem.value = e[0] === '5' ? 1 : 0
}

const currentSwitcherItem = ref(0)

const switcherList = [
  {
    label: 'Физическое лицо',
    value: 'phys'
  },
  {
    label: 'Юридическое лицо',
    value: 'entity'
  }
]

const closeAndOpenSearchBill = () => {
  isShowModalAddBill.value = false
  isShowECModalSearchBillByAddress.value = true
}

const closeAndOpenRoot = () => {
  isShowECModalSearchBillByAddress.value = false
  isShowModalAddBill.value = true
}



const filteredBills = computed(() => bills.value.filter(bill => bill.type === switcherList[currentSwitcherItem.value].value))
</script>

<style scoped lang="scss">
.bills{
  margin-top: 20px;
  &__empty{
    margin-top: 50px;
    text-align: center;
    margin-bottom: 30px;
    p{
      margin-top: 20px;
      opacity: .7;
      font-size: 16px;
    }
  }
}

.content{
  position: relative;
  // min-height: 600px;
  @media (max-width: 1100px){
    min-height: 530px;
  }
  @media (max-width: 740px){
    min-height: 600px;
  }
  @media (max-width: 400px){
    min-height: 500px;
  }
}

.switcher{
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: white;
  border-radius: 14px;
  padding: 6px;
  margin-bottom: 20px;
  &__item{
    isolation: isolate;
    text-align: center;
    transition: ease .3s;
    padding: 6px 10px;
    cursor: pointer;
    width: 50%;
    &.active{
      color: white;
    }
  }
  &.active{
    position: relative;
    &:before{
      content: '';
      transition: ease .3s;
      top: 5px;
      left: 5px;
      position: absolute;
      width: 50%;
      height: 36px;
      background: $ec-green;
      border-radius: 10px;
    }
    &-1{
      &:before{
        left: calc(50% - 5px);
      }
    }
  }
}

.preloader{
  margin-top: 30px;
  width: 100%;
}
</style>

