<template>
  <ECModal
    @close="$emit('close')"
    :title="isAutoPayOn ? 'Настроить автоплатеж' : 'Подключение автоплатежа'"
  >
    <form id="auto-pay" class="content" @submit.prevent="">
      <div class="form-item">
        <label>Номер лицевого счета</label>
        <ECInput
          v-model="number"
          placeholder=""
          type="text"
          masked="bill"
          disabled
        />
      </div>

      <div class="form-item">
        <label>Сумма автоплатежа</label>
        <ECInput
          v-model="summ"
          placeholder="0 ₽"
          type="number"
        />
      </div>

      <div class="form-item">
        <label>Число месяца совершения автоплатежа</label>
        <ECSelect
          :items="dateItems"
          @changeSelect="dateItemSelected = $event"
          :selectedItem="dateItemSelected"
          :maxHeight="185"
          top
        />
      </div>

      <div
        class="modal-info"
        v-if="dateItemSelected.id === '29' || dateItemSelected.id === '30' || dateItemSelected.id === '31'"
      >
        Если в месяце нет указанного вами числа, то автоплатеж будет совершен в последний день месяца
      </div>

      <div class="form-item">
        <label>{{isAutoPayOn ? 'Изменить способ оплаты' : 'Выберите способ оплаты'}}</label>
        <div class="cards-list">
          <div
            class="card"
            :class="{active: activeCard === ''}"
            @click="activeCard = ''"
          >
            <img src="@/assets/images/cards/new.svg" class="card__preview">
            <div class="card__label">Новая карта</div>
          </div>

          <div
            class="card"
            v-for="card in cards"
            :key="card.card_id"
            :class="{active: card.card_id === activeCard}"
            @click="activeCard = card.card_id"
          >
            <img v-if="card.pay_system === 'Visa'" src="@/assets/images/cards/Visa.svg" class="card__preview">
            <img v-else-if="card.pay_system === 'MasterCard'" src="@/assets/images/cards/MasterCard.svg" class="card__preview">
            <img v-else-if="card.pay_system === 'МИР'" src="@/assets/images/cards/МИР.svg" class="card__preview">
            <img v-else src="@/assets/images/cards/default.svg" class="card__preview">

            <div class="card__label">** {{card.maskedPan.split('').splice(12, 4).join('')}}</div>
          </div>
        </div>
      </div>

      <transition name="bottom">
        <div v-if="error" class="form-item error">{{error}}</div>
      </transition>

      <ECButton
        :label="isAutoPayOn ? 'Сохранить' : 'Подключить автоплатеж'"
        view="primary"
        @click="sendForm"
        :disabled="!validate"
      />

      <ECButton
        label="Отключить автоплатеж"
        view="link"
        @click="autopayOff"
        v-if="isAutoPayOn"
      />
    </form>
  </ECModal>
</template>

<script setup>
import { ref, computed, onMounted, defineEmits, defineProps } from 'vue'
import useApi from '@/services/useApi'
import { useRouter } from 'vue-router'

const props = defineProps({
  billNumber: {
    type: String,
    required: true,
  },
  isAutoPayOn: {
    type: Object,
    default: null
  }
})
const emit = defineEmits(['close', 'successful', 'autopayOff'])

const router = useRouter()
const number = ref('')
const summ = ref('')
const cards = ref([])
const error = ref('')
const activeCard = ref(null)

const dateItems = ref([]);
const dateItemSelected = ref({
  id: '28',
  label: '28'
});

onMounted(()=> {
  number.value = props.billNumber

  for(let i=1; i<=31; i++){ dateItems.value.push({id: String(i),label: String(i)})}
  getCards()

  if(props.isAutoPayOn){
    summ.value = String(props.isAutoPayOn.sum)
    dateItemSelected.value = {
      id: String(props.isAutoPayOn.pay_day),
      label: String(props.isAutoPayOn.pay_day)
    }
    activeCard.value = props.isAutoPayOn.card_id
  }
})

const getCards = async() => {
  const req = await useApi('get', 'payment/cards')
  cards.value = req.cards
}

const sendForm = async() => {
  error.value = ''

  const payload = {
    bill_number: number.value,
    card_id: activeCard.value,
    payDay: +dateItemSelected.value.id,
    summ: +summ.value
  }
  const req = await useApi(props.isAutoPayOn ? 'patch' : 'post', 'payment/auto', payload)
  if(req.error){
    error.value = req.error
  }
  else if(req.status === 'successful' && req.url){
    window.location.assign(req.url)
    return
  }
  else if(req.status === 'successful'){
    emit('close')
    emit('successful')

    router.push({query: { autosave_status: 'success' }})
    return
  }
}

const autopayOff = () => {
  emit('close')
  emit('autopayOff')
}

const validate = computed(()=> activeCard.value !== null && summ.value.length && summ.value !== '0')
</script>

<style scoped lang="scss">
.form-item{
  margin-bottom: 20px;
}
.cards-list{
  display: flex;
  flex-wrap: wrap;
  gap: 0 10px;
}

.card{
  width: 70px;
  height: 50px;
  border-radius: 16px;
  cursor: pointer;
  margin-bottom: 30px;
  position: relative;
  border: 2px solid transparent;
  border-radius: 18px;
  transition: ease .3s;
  &:nth-child(4),
  &:nth-child(8),
  &:nth-child(12){
    margin-right: 0;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  &__label{
    position: absolute;
    top: 50px;
    left: 50%;
    font-size: 12px;
    white-space: nowrap;
    transform: translateX(-50%);
  }
  >img{
    transition: ease .3s;
  }
  &.active{
    border: 2px solid $ec-green;
    >img{
      transform: scale(0.8);
      border-radius: 16px;
      overflow: hidden;
    }
  }
}

.modal-info{
  font-size: 14px;
}


.error{
  margin-bottom: 10px;
  color: #FF7A7A;
  text-align: center;
}
</style>
