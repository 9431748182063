<template>
  <ECModal
    @close="$emit('close')"
    title="Поиск лицевого счета по адресу"
  >
    <form id="search-bill-by-address" class="content" @submit.prevent="">

      <transition name="quick-hide">
        <div
          class="ec-modal__back-btn"
          @click="bills = []"
          v-if="bills.length"
        >
          <ECRoundButton img="back_w"/>
        </div>
      </transition>

      <template v-if="!bills.length">
        <ECSwitcher
          :list="switcherList"
          :active="currentSwitcherItem"
          @change="currentSwitcherItem = $event"
          grey
        />

        <transition name="bottom">
          <div
            v-if="currentSwitcherItem === 1"
            class="form-item"
            type="inn"
          >
            <label :class="{required: currentSwitcherItem === 1}">ИНН организаци</label>
            <ECInput
              v-model="dadataInn"
              masked="inn"
              placeholder="Введите ИНН организации"
            />
            <ECDadataSearch
              @setDadata="setDadataInn($event)"
              :search="dadataInn"
              type="inn"
              maxHeight="175px"
            />
          </div>
        </transition>


        <div class="form-item">
          <label :class="{required: currentSwitcherItem === 0}">Адрес обслуживания</label>
          <ECInput
            v-model="dadataAddress"
            placeholder="Введите адрес"
          />
          <ECDadataSearch
            @setDadata="setDadataAddress($event)"
            :search="dadataAddress"
            maxHeight="175px"
          />
        </div>
        <div v-if="error" class="error-search">{{ error }}</div>
      </template>

      <template v-else>
        <div
          class="bill-item"
          v-for="bill in bills"
        >
          <div class="bill-item__row">
            <div class="left">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.5 3.95531C4.22349 3.95531 4 4.17411 4 4.44372V12V19.5559C4 19.8258 4.22386 20.0447 4.5 20.0447H14.6328C14.8356 20.0447 15 19.884 15 19.6857V5.42179C15 4.89698 15.1208 4.39978 15.3368 3.95531H4.5ZM18 2.03465V2H4.5C3.11966 2 2 3.0935 2 4.44372V12V19.5559C2 20.9057 3.11929 22 4.5 22H14.6328C15.9402 22 17 20.9639 17 19.6857V8.84358H20.9505C21.5301 8.84358 22 8.3842 22 7.81752V5.42179C22 3.53199 20.433 2 18.5 2C18.3302 2 18.1633 2.01182 18 2.03465ZM20 6.88827V5.42179C20 4.61187 19.3284 3.95531 18.5 3.95531C17.6716 3.95531 17 4.61187 17 5.42179V6.88827H20ZM6 12.7542C6 12.2142 6.44772 11.7765 7 11.7765H12C12.5523 11.7765 13 12.2142 13 12.7542C13 13.2941 12.5523 13.7318 12 13.7318H7C6.44772 13.7318 6 13.2941 6 12.7542ZM7 6.88827C6.44772 6.88827 6 7.32598 6 7.86592C6 8.40586 6.44772 8.84358 7 8.84358H12C12.5523 8.84358 13 8.40586 13 7.86592C13 7.32598 12.5523 6.88827 12 6.88827H7Z" fill="#57BA5E"/>
              </svg>
              <div class="info">
                <span>{{ bill.bill_number }}</span>
                <p>{{bill.addr.length ? bill.addr : dadataAddressValue }}</p>
              </div>
            </div>
            <div @click="copyBill(bill.bill_number, 'Aдрес лицевого счета скопирован!')" class="right">
              <svg class="copy" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6 19L7 19C7 20.6569 8.34315 22 10 22H18C19.6569 22 21 20.6569 21 19V8C21 6.34314 19.6569 5 18 5C18 3.34315 16.6569 2 15 2H6C4.34315 2 3 3.34315 3 5L3 16C3 17.6569 4.34315 19 6 19ZM8 17L8.00154 17L15 17C15.5523 17 16 16.5523 16 16L16 6V5C16 4.44771 15.5523 4 15 4L6 4C5.44772 4 5 4.44771 5 5L5 16C5 16.5523 5.44772 17 6 17H7.99846L8 17ZM19 8C19 7.44771 18.5523 7 18 7L18 16C18 17.6569 16.6569 19 15 19L9 19C9 19.5523 9.44772 20 10 20H18C18.5523 20 19 19.5523 19 19V8ZM13 6C13.5523 6 14 6.44772 14 7C14 7.55228 13.5523 8 13 8H8C7.44772 8 7 7.55228 7 7C7 6.44772 7.44772 6 8 6H13ZM14 10.5C14 9.94772 13.5523 9.5 13 9.5H8C7.44772 9.5 7 9.94772 7 10.5C7 11.0523 7.44772 11.5 8 11.5H13C13.5523 11.5 14 11.0523 14 10.5ZM13 13C13.5523 13 14 13.4477 14 14C14 14.5523 13.5523 15 13 15H8C7.44772 15 7 14.5523 7 14C7 13.4477 7.44772 13 8 13H13Z" fill="#C6C6C6"/>
              </svg>
            </div>
          </div>
          <div @click="choseBill(bill.bill_number)" class="bill-item__row choose">
            <div class="left green">
              Выбрать
            </div>
            <div class="right">
              <svg class="arr" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.33104 14.7428C5.92053 14.3734 5.88726 13.7411 6.25671 13.3306L9.95396 9.22251C10.068 9.09574 10.068 8.9033 9.95396 8.77653L6.25671 4.66848C5.88725 4.25797 5.92053 3.62568 6.33104 3.25622C6.74155 2.88677 7.37384 2.92004 7.7433 3.33055L11.4405 7.4386C12.2392 8.32598 12.2392 9.67306 11.4405 10.5604L7.7433 14.6685C7.37384 15.079 6.74155 15.1123 6.33104 14.7428Z" fill="#C6C6C6"/>
              </svg>
            </div>
          </div>
        </div>
      </template>

      <!-- г Стерлитамак, пр-кт Ленина, д 1, кв 20 -->

      <div v-if="!error.length && !bills.length" class="description">Убедитесь в корректности вводимых данных. Адрес должен быть точен до квартиры/офиса. Если Вы не нашли свой лицевой счет, обратитесь к нам. Мы всегда поможем!</div>
      <div v-if="!error.length && bills.length" class="description">
        По указанному адресу выявлено {{ bills.length }} лицевых счетов у разных {{ currentSwitcherItem === 0 ? 'физических' : 'юридических'  }} лиц. Проверьте полноту заполнения адреса, заполните данные (дом, квартира, офис и т.д.).
      </div>

      <ECButton
        v-if="!bills.length"
        label="Найти"
        view="primary"
        @click="findBills"
        :disabled="!validate"
      />
    </form>

    <transition name="left">
      <ECNotification
        v-if="notificationText"
        :text="notificationText"
        :status="notificationStatus"
      />
    </transition>
  </ECModal>
</template>

<script setup>

    // г Стерлитамак, пр-кт Ленина, д 22, кв 5
    // г Стерлитамак, ул Цементников, д 5, кв 1

import { defineEmits, ref, computed, onMounted, watchEffect, watch } from 'vue'
import store from '@/store'
import axios from 'axios'
import useApi from '@/services/useApi'
import ECSwitcher from '@/components/ECSwitcher'
import ECDadataSearch from '@/components/ECDadataSearch'
import ECRoundButton from '@/components/common/ECRoundButton.vue'
import ECNotification from '@/components/ui/ECNotification.vue'
import { useRouter } from 'vue-router'

const router = useRouter()

const emit = defineEmits(['close', 'closeAndOpenRoot'])

const switcherList = ref([
  {
    label: 'Физ. лицо',
    value: 'phys'
  },
  {
    label: 'Юр. лицо',
    value: 'entity'
  }
])
const currentSwitcherItem = ref(0)

const dadataAddress = ref('')
const dadataAddressValue = ref('')

const dadataInn = ref('')
const dadataInnValue = ref('')

const bills = ref([])

watch(() => currentSwitcherItem.value, () => {
  dadataAddress.value = ''
  dadataAddressValue.value = ''
  dadataInn.value = ''
  dadataInnValue.value = ''
  error.value = ''
})

const validate = computed(()=> {
  if(currentSwitcherItem.value === 0 && dadataAddressValue.value.length){
    return true
  }
  else if(currentSwitcherItem.value === 1 && dadataInnValue.value.length){
    return true
  }
  else {
    return false
  }
})

const setDadataInn = v =>{
  dadataInn.value = v.inn
  dadataInnValue.value = v.inn
}

const setDadataAddress = v =>{
  dadataAddress.value = v.value
  dadataAddressValue.value = v.value
}

const findBills = async() => {
  store.dispatch('setGlobalPreloader', true)

  error.value = ''

  const req = await useApi('post', 'bills/get_by_addr', {
    addr: dadataAddressValue.value,
    bill_type: switcherList.value[currentSwitcherItem.value].value,
    inn: dadataInnValue.value
  })



  if(req.error){
    error.value = req.error
  }
  else if(req.status === 'successful'){

    if(req.bils.bills.length === 1){
      router.push({query: { bill: req.bils.bills[0].bill_number}})
      emit('closeAndOpenRoot')
    } else {
      bills.value = req.bils.bills
    }
  }

  store.dispatch('setGlobalPreloader', false)
}

const error = ref('')

const notificationText = ref('')
const notificationStatus = ref(true)

const showNotification = (text, status = true) => {
  notificationText.value = text
  notificationStatus.value = status
  setTimeout(()=> {
    notificationText.value = ''
  }, 4000)
}



const choseBill = bill => {
  router.push({query: { bill }})
  emit('closeAndOpenRoot')
}

const copyBill = (bill, text) => {
  navigator.clipboard.writeText(bill)
    .then(() => {
      showNotification(text)
    })
}

</script>

<style scoped lang="scss">
.ec-modal__back-btn{
  position: absolute;
  top: 0;
}
.bill-item{
  padding: 16px;
  background: $ec-grey;
  border-radius: 16px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 17px 0;
  &__row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    &.choose{
      cursor: pointer;
    }
    .left, .right{
      display: flex;
      align-items: center;
      .copy, .arr{
        cursor: pointer;
        path{
          transition: ease .3s;
        }
        &:hover{
          path {
            fill: #B2B2B2;
          }
        }
      }
      .info{
        padding-left: 7px;
        max-width: 215px;
          span{
          font-size: 14px;
          line-height: 16px;
        }
        p{
          opacity: 0.5;
        }
      }
    }
    .right{
      padding-left: 10px;
    }
  }
}
.description,
.error-search{
  font-size: 16px;
  line-height: 20px;
  opacity: 0.5;
  margin-bottom: 20px;
  text-align: center;
}
.error-search{
  color: $ec-red;
  opacity: 1;
}
.green{
  color: $ec-green;
  transition: ease .3s;
  &:hover{
    color: #64CC6C;
  } 
}
</style>