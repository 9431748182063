<template>
  <ECModal
    @close="$emit('close')"
    :title="title"
  >
    <form id="confirm-modal" class="content" @submit.prevent="">

      <div class="modal-info">{{subtitle}}</div>

        <ECButton
          label="Да"
          view="primary"
          @click="confirmAndClose"
        />

        <ECButton
          label="Отмена"
          view="link"
          @click="$emit('close')"
        />
    </form>
  </ECModal>
</template>

<script setup>
import { defineEmits, defineProps } from 'vue'
import useApi from '@/services/useApi'


const props = defineProps({
  title:{
    type: String,
    required: true,
  },
  subtitle:{
    type: String,
  }
})
const emit = defineEmits(['close', 'confirm'])

const confirmAndClose = () => {
  emit('confirm')
  emit('close')
}
</script>

<style scoped lang="scss"></style>
