<template>
  <div
    class="ec-input"
    :class="{
      'ec-input--green': green,
      'ec-input--error': error !== '',
      'ec-input--show-pass': showPassword,
    }"
  >
    <div
      v-if="showPassword"
      class="ec-input__show-pass"
      @click="$emit('switch-show-pass')"
    >
      <svg v-if="type === 'password'" width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M15.0954 3.68424C13.905 2.20284 11.5399 0 7.99985 0C4.45983 0 2.09466 2.20284 0.904343 3.68424C0.27798 4.46377 0.277979 5.53623 0.904342 6.31576C2.09466 7.79716 4.45983 10 7.99985 10C11.5399 10 13.905 7.79716 15.0954 6.31576C15.7217 5.53623 15.7217 4.46377 15.0954 3.68424ZM11.9998 5C11.9998 7.20914 10.209 9 7.99985 9C5.79071 9 3.99985 7.20914 3.99985 5C3.99985 2.79086 5.79071 1 7.99985 1C10.209 1 11.9998 2.79086 11.9998 5ZM7.99985 7C9.10442 7 9.99985 6.10457 9.99985 5C9.99985 3.89543 9.10442 3 7.99985 3C6.89528 3 5.99985 3.89543 5.99985 5C5.99985 6.10457 6.89528 7 7.99985 7Z" fill="#828282"/></svg>
      <svg v-if="type === 'text'" style="margin-top: 6px;" width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M15.0954 5.68426C14.8134 5.33342 14.4656 4.94211 14.0519 4.54918L11.9997 6.94342C12 6.96225 12.0001 6.98112 12.0001 7.00003C12.0001 9.02074 10.5017 10.6915 8.55541 10.9618L7.67097 11.9936C7.77944 11.9979 7.88907 12 7.99985 12C11.5399 12 13.905 9.79718 15.0954 8.31579C15.7217 7.53626 15.7217 6.46379 15.0954 5.68426ZM4.00051 7.05629C4.00025 7.03757 4.00012 7.01881 4.00012 7.00003C4.00012 4.97942 5.49835 3.30875 7.4445 3.0383L8.32898 2.00642C8.22042 2.00219 8.11071 2.00003 7.99985 2.00003C4.45983 2.00003 2.09466 4.20287 0.904343 5.68426C0.27798 6.46379 0.27798 7.53626 0.904342 8.31579C1.18628 8.66667 1.53413 9.05803 1.9479 9.45101L4.00051 7.05629Z" fill="#828282"/><path d="M13.7682 1.64021C14.1218 1.21593 14.0644 0.585368 13.6402 0.231804C13.2159 -0.12176 12.5853 -0.0644362 12.2318 0.359841L10.5328 2.3986L9.71184 3.38374L8.34085 5.02893L6.12262 7.6908L4.75221 9.33529L3.59258 10.7268L2.23175 12.3598C1.87819 12.7841 1.93551 13.4147 2.35979 13.7682C2.78407 14.1218 3.41463 14.0645 3.7682 13.6402L5.46711 11.6015L6.28819 10.6162L7.65914 8.97107L9.87753 6.30901L11.2479 4.66457L12.4073 3.27331L13.7682 1.64021Z" fill="#828282"/></svg>
    </div>

    <input
      :disabled="disabled"
      :placeholder="placeholder"
      :type="type"
      :value="modelValue"
      :maxlength="maxLength"
      @input="onInput"
      v-maska="isMasked"
      autocomplete="off"
      step="any"
      :class="{greenBG: green && modelValue.length}"
      ref="target"
    />

    <p v-if="price" class="price">₽</p>

    <div class="error-message">{{error}}</div>
  </div>
</template>

<script setup>
import { ref, defineEmits, defineProps, computed, onMounted } from 'vue'
import { maska as vMaska } from 'maska'

const props = defineProps({
  modelValue: {
    type: String,
    default: null,
  },
  maxLength: {
    type: String,
  },
  placeholder: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: 'text',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  error: {
    type: String,
    default: '',
  },
  showPassword: {
    type: Boolean,
    default: false,
  },
  masked: {
    type: String,
    default: '',
  },
  green: {
    type: Boolean,
    default: false
  },
  price: {
    type: Boolean,
    default: false
  },
  focus: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['update:modelValue', 'switch-show-pass', 'onInput'])

const target = ref(null)

onMounted(()=> {
  if(props.focus){
    target.value.focus()
  }
})

const isMasked = computed(()=>{
  if(props.masked === 'code') return '####'
  if(props.masked === 'inn') return '############'
  if(props.masked === 'kpp') return '#########'
  if(props.masked === 'tel') return '+7(###)###-##-##'
  if(props.masked === 'bill') return '############'
})

const onInput = (e) => {
  emit('onInput')
  emit('update:modelValue', e.target.value)
}
</script>


<style scoped lang="scss">
.ec-input {
  position: relative;
  width: 100%;

  &__show-pass{
    position: absolute;
    right: 11px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    transition: ease .3s;
    &:hover{
      opacity: .6;
    }
  }
  input {
    background: $ec-grey;
    border-radius: 14px;
    padding: 7px 18px;
    border: 1px solid transparent;
    width: 100%;
    color: $ford-medium-dark;
    &:focus {
      outline: none;
    }
    &:disabled {
      color: rgba($ford-medium-dark, .5);
      opacity: 0.5;
    }
    &::placeholder {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.01em;
      color: rgba($primary-font-color, 0.3);
    }
  }
  .error-message{
    color: #FF7A7A;
    text-align: right;
  }
  &--green{
    input{
      font-size: 36px;
      line-height: 52px;
      text-align: center;
      background: white;
      border-bottom: 1px solid rgba(22, 22, 22, 0.6);
      border-radius: 0;
      font-family: "TT Norms Pro Regular";
      padding: 0 17px;
      transition: ease .2s;
    }
    .greenBG{
      border-bottom: 1px solid #57BA5E;
    }
    &.ec-input--error{
      input {
        border: none;
        border: 2px solid transparent;
        border-bottom: 1px solid #FFAFAF;
        background: white;
      }
    }

  }
  &--show-pass{
    input {
      padding-right: 38px;
    }
  }
  &--error {
    input {
      background: rgba(#FFAFAF, 20%);
      border: 1px solid #FFAFAF;
    }
  }
}

.price{
  font-size: 36px;
  line-height: 52px;
  position: absolute;
  right: 0;
  top: 2px;
  font-family: "TT Norms Pro Regular"
}

// hide arrows
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
