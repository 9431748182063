<template>
  <ECModal
    @close="$emit('close')"
    title="Подтвердите телефон"
  >
    <form id="confirm-tel" class="content" @submit.prevent="">
      <div class="modal-info">Введите код, отправленный <br/> на ваш номер</div>

      <div class="code-block">

        <input
          v-model="code"
          type="text"
          ref="input"
          v-maska="'####'"
          @input="oninput"
        />

        <div class="row">
          <div :class="{green: code[0], errorr: error}"></div>
          <div :class="{green: code[1], errorr: error }"></div>
          <div :class="{green: code[2], errorr: error }"></div>
          <div :class="{green: code[3], errorr: error}"></div>
        </div>
      </div>

      <transition name="bottom">
        <div v-if="error" class="error">{{error}}</div>
      </transition>

      <div v-if="timer > 1" class="timer">0:{{timer > 9 ? '' : '0'}}{{timer}}</div>

      <div class="form-item">
        <ECButton
          label="Далее"
          view="primary"
          :disabled="!validate"
          @click="sendForm"
        />

        <ECButton
          label="Отправить код повторно"
          view="link"
          :disabled="timer > 1"
          @click="sendCodeAgain"
        />
      </div>

      <span class="policy" v-if="loginType !== 3">
        <span>Продолжая, вы подтверждаете, что ознакомлены с </span>
        <a class="link" target="_blank" href="/docs/Polzovatelskoe soglashenie roecocity ru.pdf">пользовательским соглашением</a>
        <span> и </span>
        <a class="link" target="_blank" href="/docs/Politika obrabotki personalnykh dannykh.pdf">политикой обработки персональных данных</a>
      </span>
    </form>
  </ECModal>
</template>

<script setup>
import { ref, computed, onMounted, watchEffect,  defineEmits, defineProps } from 'vue'
import { maska as vMaska } from 'maska'
import useApi from '@/services/useApi'
import store from '@/store'
import { useRouter } from 'vue-router'

const router = useRouter()

const props = defineProps({
  phone: {
    type: String,
    required: true,
  },
  loginType: {
    type: Number,
    default: 4
  }
})
const emit = defineEmits(['close', 'sendSmsCode', 'successCode'])

const input = ref(null)
const code = ref('')
const timer = ref(59)
const error = ref('')

const setTime = () => {
  timer.value === 59
  setInterval(()=> timer.value--, 1000)
}

onMounted(()=> {
  input.value.focus()
  setTime()
})

const oninput = () => {
  if(code.value.length === 4){
    sendForm()
    return
  }
}

const validate = computed(() => code.value.length === 4 )

const sendForm = async() => {

  if(props.loginType === 2 || props.loginType === 1){
    const req = await useApi('post', `users/check_by_${props.loginType === 2 ? 'inn_phone' : 'personal_bill'}`, {
      code: code.value,
      phone: props.phone,
    })
    if(req.error){
      error.value = req.error
    }
    if(req.token){
      emit('close')
      store.dispatch('login', req)
      if(window.innerWidth < 740){
        router.push('/account')
      }
    }
  }
  else if(props.loginType === 3){
    const req = await useApi('post', 'users/forgot/check_sms', {
      phone: props.phone,
      code: code.value,
    })
    if(req.error){
      error.value = req.error
    }
    if(req.status === 'successful'){
      emit('close')
      emit('successCode')
    }
  }
  else if(props.loginType === 5){
    const req = await useApi('post', 'users/info_check', {
      phone: props.phone,
      code: code.value,
    })
    if(req.error){
      error.value = req.error
    }
    if(req.status === 'successful'){
      emit('close')
      emit('successCode')
    }
  }
  else {
    const req = await useApi('post', 'users/check', {
      code: code.value,
      phone: props.phone,
    })
    if(req.error){
      error.value = req.error
    }
    if(req.status === 'successful'){
      emit('close')
      emit('successCode')
    }
  }
}

const sendCodeAgain = async() => {
  timer.value = 60
  code.value = ''
  error.value = ''

  const req = await useApi('post', 'users/send_sms_again', {
    phone: props.phone,
  })
}
</script>

<style scoped lang="scss">
.code-block{
  margin: 0 auto 20px;
  position: relative;
  input{
    margin: 0 auto;
    display: block;
    font-size: 40px;
    width: 151px;
    letter-spacing: 17px;
    font-size: 36px;
    line-height: 52px;
    transform: translateX(4px);
    border: none;
    font-family: 'TT Norms Pro Regular';
  }
  .row{
    display: flex;
    gap: 0 10px;
    position: absolute;
    left: 80px;
    bottom: -2px;

    div{
      width: 28px;
      height: 1px;
      border-radius: 1px;
      background: #737373;
      transition: ease .3s;

      &.green{
        background: $ec-green;
      }
      &.errorr{
        background: $ec-red;
      }
    }
  }
}


.error{
  margin: 0 auto;
  margin-bottom: 20px;
  color: #FF7A7A;
  text-align: center;
}

.timer{
  margin-bottom: 20px;
  text-align: center;
}

.policy{
  text-align: center;
  margin-top: 0px;
  display: block;
  line-height: 100%;
  margin-bottom: 20px;
  a{
    font-size: 16px;
    line-height: 20px;
    color: $ec-green;
    text-decoration: none;
  }
  span{
    font-size: 16px;
    line-height: 20px;
    opacity: .5;
  }
}
</style>
