

export default function prettyPhone(p) {
  let newP = []
  p.split('').forEach((el, idx) => {
    if(idx == 0){newP.push(el)}
    if(idx == 1){ newP.push(el)}
    if(idx == 2){newP.push('(');newP.push(el)}
    if(idx == 3){ newP.push(el)}
    if(idx == 4){newP.push(el); newP.push(')');newP.push(' ')}
    if(idx == 5){newP.push(el) }
    if(idx == 6){newP.push(el)}
    if(idx == 7){newP.push(el); newP.push('-')}
    if(idx == 8){newP.push(el)}
    if(idx == 9){newP.push(el); newP.push('-')}
    if(idx == 10){newP.push(el)}
    if(idx == 11){newP.push(el)}
    if(idx == 12){newP.push(el)}
  });

  return newP.join('')
}
