<template>
  <div class="services">
    <div
      class="services__item"
      @click="$store.state.user.token ? isShowECModalThemeSupport = true : isShowModalSupport = true"
    >
      <img src="@/assets/images/services-support.svg" alt="">
      <span> Обратиться к рег. оператору </span>
    </div>

    <div
      v-if="!$store.state.user.token"
      class="services__item"
      @click="isShowModalPaymentExpress = true"
    >
      <img src="@/assets/images/services-bill.svg" alt="">
      <span>Экспресс-оплата услуг</span>
    </div>

    <div
      v-else
      class="services__item"
      @click="isShowModalEditUserInfo = true"
    >
      <img src="@/assets/images/services-edit-profile.svg" alt="">
      <span>{{$store.state.user.userPhone && $store.state.user.userName && $store.state.user.userEmail ? 'Редактировать профиль' : 'Дополнить информацию' }}</span>
    </div>
  </div>

  <div
    class="user-block"
    v-if="$store.state.user.token"
  >
    <div class="info-item phone">{{$store.state.user.userPhone}}</div>

    <div v-if="$store.state.user.userName" class="info-item name">{{$store.state.user.userName}}</div>
    <div v-if="$store.state.user.userEmail" class="info-item email">{{$store.state.user.userEmail}}</div>
    <div v-if="!$store.state.user.userName && !$store.state.user.userEmail" class="fill-info">Дополните информацию о себе</div>
  </div>

  <div class="support-modal" :class="{active: isShowModalSupport}">
    <ECModalSupport
      @close="isShowModalSupport = false"
    />
  </div>

  <transition name="default">
    <ECModalPaymentExpress
      v-if="isShowModalPaymentExpress"
      @closeAndOpenLogin="closeAndOpenLogin"
      @closeAndOpenSearchBill="closeAndOpenSearchBill"
      @close="isShowModalPaymentExpress = false"
    />
  </transition>

  <transition name="default">
    <ECModalEditUserInfo
      v-if="isShowModalEditUserInfo"
      @close="isShowModalEditUserInfo = false"
      @successful="closeAndOpenConfirmSms($event)"
      @saveUserData="saveUserData"
    />
  </transition>

  <transition name="default">
    <ECModalLogin
      v-if="isShowModalLogin"
      @close="isShowModalLogin  = false"
      :currentSwitcherItem="0"
      hideSwitcher
      @closeAndOpenRecovery="closeAndOpenRecovery"
    />
  </transition>

  <transition name="default">
    <ECModalPasswordRecovery
      v-if="isShowEodalPasswordRecovery"
      @close="isShowEodalPasswordRecovery = false"
      @successful="closeAndOpenConfirmSms($event)"
    />
  </transition>

  <transition name="default">
    <ECModalConfirmPhoneRecovery
      v-if="isShowModalConfirmPhoneThenRecovery"
      @close="closeAndSetPassword"
      :phone="formattedPhone(phone)"
      :loginType="3"
    />
  </transition>

  <transition name="default">
    <ECModalConfirmPhone
      v-if="isShowModalConfirmPhoneChangeUserData"
      @close="isShowModalConfirmPhoneChangeUserData = false"
      :phone="formattedPhone(phone)"
      :loginType="5"
      @successCode="successCodeChangeData"
    />
  </transition>

  <transition name="default">
    <ECModalCreatePasswordRecovery
      v-if="isShowModalCreatePasswordRecovery"
      @close="isShowModalCreatePasswordRecovery = false"
      :phone="formattedPhone(phone)"
      type="forgot"
    />
  </transition>

  <transition name="left">
    <ECNotification
      v-if="notificationText"
      :text="notificationText"
      :status="notificationStatus"
    />
  </transition>

  <transition name="default">
    <ECModalThemeSupport
      v-if="isShowECModalThemeSupport"
      @close="isShowECModalThemeSupport = false"
      @openModal="openModal"
    />
  </transition>

  <div class="disposal-modal" :class="{active: isShowECAdditionalWasteDisposal}">
    <ECAdditionalWasteDisposal
      @close="isShowECAdditionalWasteDisposal = false"
    />
  </div>

  <transition name="default">
    <ECModalSearchBillByAddress
      v-if="isShowECModalSearchBillByAddress"
      @close="isShowECModalSearchBillByAddress = false"
      @closeAndOpenRoot="closeAndOpenRoot"
    />
  </transition>
</template>


<!-- г Стерлитамак, пр-кт Ленина, д 1, кв 20 -->

<script setup>
import { ref } from 'vue'
import useApi from '@/services/useApi'
import store from '@/store'

import formattedPhone from '@/helpers/formattedPhone.js'
import ECNotification from '@/components/ui/ECNotification.vue'
import ECModalSupport from '@/components/modals/ECModalSupport.vue'
import ECModalLogin from '@/components/modals/ECModalLogin.vue'
import ECModalPaymentExpress from '@/components/modals/ECModalPaymentExpress.vue'
import ECModalEditUserInfo from '@/components/modals/ECModalEditUserInfo.vue'
import ECModalPasswordRecovery from '@/components/modals/ECModalPasswordRecovery.vue'
import ECModalConfirmPhoneRecovery from '@/components/modals/ECModalConfirmPhone.vue'
import ECModalCreatePasswordRecovery from '@/components/modals/ECModalCreatePassword.vue'
import ECModalConfirmPhone from '@/components/modals/ECModalConfirmPhone.vue'
import ECModalThemeSupport from '@/components/modals/ECModalThemeSupport.vue'
import ECModalSearchBillByAddress from '@/components/modals/ECModalSearchBillByAddress.vue'
import ECAdditionalWasteDisposal from '@/components/modals/ECAdditionalWasteDisposal'


const phone = ref('')

const changedUserData = ref({})

const isShowModalSupport = ref(false)
const isShowModalPaymentExpress = ref(false)
const isShowModalLogin = ref(false)
const isShowModalEditUserInfo = ref(false)

const isShowEodalPasswordRecovery = ref(false)
const isShowModalConfirmPhoneThenRecovery = ref(false)
const isShowModalCreatePasswordRecovery = ref(false)
const isShowModalConfirmPhoneChangeUserData = ref(false)
const isShowECModalThemeSupport = ref(false)
const isShowECAdditionalWasteDisposal = ref(false)
const isShowECModalSearchBillByAddress = ref(false)

const notificationText = ref('')
const notificationStatus = ref(true)

const closeAndOpenLogin = () => {
  isShowModalPaymentExpress.value = false
  isShowModalLogin.value = true
}

const closeAndOpenSearchBill = () => {
  isShowModalPaymentExpress.value = false
  isShowECModalSearchBillByAddress.value = true
}

const closeAndOpenRecovery = () => {
  isShowModalLogin.value = false
  isShowEodalPasswordRecovery.value = true
}

const closeAndOpenRoot = () => {
  isShowECModalSearchBillByAddress.value = false
  isShowModalPaymentExpress.value = true
}

const closeAndOpenConfirmSms = (e) => {
  phone.value = e
  isShowModalEditUserInfo.value = false
  isShowEodalPasswordRecovery.value = false
  isShowModalConfirmPhoneThenRecovery.value = true
}

const closeAndSetPassword = () => {
  isShowModalConfirmPhoneThenRecovery.value = false
  isShowModalCreatePasswordRecovery.value = true
}


const saveUserData = async (data) => {
  changedUserData.value = data

  const req = await useApi('post', 'users/info', data)

  if(req.status === 'successful'){
    store.dispatch('setUsersInfo', data)
    showNotification('Данные были успешно изменены')
  }
  if(req.status === 'need confirm'){
    phone.value = data.phone
    isShowModalConfirmPhoneChangeUserData.value = true
  } else {
    showNotification(data.error, false)
  }
}


const successCodeChangeData = () => {
  store.dispatch('setUsersInfo', changedUserData.value)
  showNotification('Данные были успешно изменены')
}

const showNotification = (text, status = true) => {
  notificationText.value = text
  notificationStatus.value = status
  setTimeout(()=> {
    notificationText.value = ''
  }, 4000)
}

const openModal = type => {
  isShowECModalThemeSupport.value = false
  switch (type) {
    case 1:
      isShowECAdditionalWasteDisposal.value = true
      break;
    case 2:
      isShowModalSupport.value = true
      break;
    default:
      isShowModalSupport.value = true
  }
}
</script>

<style scoped lang="scss">
.services{
  display: flex;
  justify-content: space-between;
  @media (max-width: 1100px){
    flex-direction: column;
    gap: 16px;
  }
  &__item{
    width: calc(50% - 8px);
    height: 68px;
    border-radius: 16px;
    background: white;
    padding: 18px;
    line-height: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: ease .3s;
    img{
      width: 24px;
      height: 24px;
    }
    span{
      font-size: 16px;
      line-height: 16px;
      max-width: 115px;
    }
    &:hover{
      background: #ffffff82;
    }
    @media (max-width: 1100px){
      width: 100%;
      justify-content: flex-start;
      padding: 18px;
      height: auto;
      span{
        max-width: 100%;
        margin-left: 15px;
      }
    }
  }
}

.user-block{
  padding: 16px;
  background: white;
  border-radius: 24px;
  margin-top: 25px;
  .phone{
    font-size: 20px;
    margin-bottom: 10px;
  }
  .name{
    margin-bottom: 10px;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .email, .fill-info{
    opacity: 0.5;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.info-item{
  &:last-child{
    margin-bottom: 0 !important;
  }
}

.support-modal,
.disposal-modal{
  opacity: 0;
  visibility: hidden;
  transition: ease .3s;
  position: fixed;
  z-index: 99999;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  &.active{
    opacity: 1;
    visibility: visible;
  }
}
</style>

