<template>
  <ECModal
    @close="$emit('close')"
    title="Восстановление пароля"
  >
    <form id="password-recovery" class="content" @submit.prevent="">
      <div class="form-item">
        <label>Телефон</label>
        <ECInput
          placeholder="+7"
          masked="tel"
          v-model="phone"
          :error="phoneError"
        />
      </div>
      <div class="form-item">
        <ECButton
          label="Далее"
          view="primary"
          :disabled="!validate"
          @click="sendForm"
        />
      </div>
    </form>
  </ECModal>
</template>

<script setup>
import { computed, defineEmits, ref, onMounted } from 'vue'
import useApi from '@/services/useApi'
import formattedPhone from '@/helpers/formattedPhone.js'
import { useRoute } from 'vue-router'

const route = useRoute()

const emit = defineEmits(['close', 'successful'])

const phone = ref('')
const phoneError = ref('')

onMounted(()=> {
  if(route.query.tel){
    phone.value = route.query.tel
  }
})

const sendForm = async() => {
  phoneError.value = ''

  const req = await useApi('post', 'users/forgot/check_phone', {
    phone: formattedPhone(phone.value)
  })
  if(req.error){
    phoneError.value = req.error
  }
  if(req.status === 'successful'){
    const req = await useApi('post', 'users/forgot/send_sms', {
      phone: formattedPhone(phone.value)
    })
    emit('close')
    emit('successful', formattedPhone(phone.value))
  }
}

const validate = computed(()=> phone.value.length === 16)
</script>

<style scoped lang="scss">
.form-item{
  margin-bottom: 20px;
}
</style>
