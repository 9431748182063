<template>
  <ECModal
    @close="$emit('close')"
    title="Создание пароля"
  >
    <form id="create-pass" class="content" @submit.prevent="">
      <div class="modal-info">Создайте пароль для своего личного кабинета</div>

      <div class="form-item">
        <label>Придумайте пароль</label>
        <ECInput
          v-model="password"
          placeholder="Введите не менее 3 символов"
          :type="typeOfPasswordInput"
          :show-password="true"
          @switch-show-pass="typeOfPasswordInput === 'password' ? typeOfPasswordInput = 'text' : typeOfPasswordInput = 'password'"
        />
        <div class="rate-pass">
          <span>Надежность</span>
            <div :class="[rate1]" />
            <div :class="[rate2]" />
            <div :class="[rate3]" />
        </div>
      </div>

      <div class="form-item">
        <label>Повторите пароль</label>
        <ECInput
          v-model="repeatPassword"
          placeholder=""
          type="password"
        />
      </div>

      <transition name="bottom">
        <div v-if="error" class="error">{{ error }}</div>
      </transition>

      <div class="form-item">
        <ECButton
          label="Далее"
          view="primary"
          :disabled="!validate"
          @click="sendForm"
        />
      </div>
    </form>
  </ECModal>
</template>

<script setup>
import { ref, computed, defineEmits, defineProps, watchEffect } from 'vue'
import useApi from '@/services/useApi'
import store from '@/store'
import { useRouter } from 'vue-router'

const props = defineProps({
  phone: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    default: ''
  }
})
const emit = defineEmits(['close', 'success'])

const router = useRouter()
const password = ref('')
const repeatPassword = ref('')
const typeOfPasswordInput = ref('password')
const error = ref('')

const validate = computed(() => password.value.length >= 3 && repeatPassword.value === password.value )

const sendForm = async() => {

    const req = await useApi('post', (props.type === 'forgot' ? 'users/forgot/set_password' : 'users/set_password'), {
      password: password.value,
      phone: props.phone,
    })

    if(req.error){
      error.value = req.error
    }

  if(req.token){
    error.value = ''
    emit('close')
    emit('success')
    store.dispatch('login', req)
    if(window.innerWidth < 740){
      router.push('/account')
    }
  }
}

const rate1 = ref('')
const rate2 = ref('')
const rate3 = ref('')


watchEffect(() => {
  let rate = 0

  const s_letters = "qwertyuiopasdfghjklzxcvbnm"
  const b_letters = "QWERTYUIOPLKJHGFDSAZXCVBNM"
  const digits = "0123456789"

  var is_s = false
  var is_b = false
  var is_d = false

  for (var i = 0; i < password.value.length; i++) {
    if (!is_s && s_letters.indexOf(password.value[i]) != -1) is_s = true
    else if (!is_b && b_letters.indexOf(password.value[i]) != -1) is_b = true
    else if (!is_d && digits.indexOf(password.value[i]) != -1) is_d = true
  }
  if (is_s) rate++
  if (is_b) rate++
  if (is_d) rate++

  if(rate === 1){
    rate1.value = 'red'
    rate2.value = ''
    rate3.value = ''
  }
  else if(rate === 2){
    rate1.value = 'yellow'
    rate2.value = 'yellow'
    rate3.value = ''
  }
  else if(rate === 3){
    rate1.value = 'green'
    rate2.value = 'green'
    rate3.value = 'green'
  }
  else {
    rate1.value = ''
    rate2.value = ''
    rate3.value = ''
  }
})
</script>

<style scoped lang="scss">
.content{
  margin-top: -4px;
}
.error{
  margin: 0 auto;
  margin-bottom: 10px;
  color: #FF7A7A;
  text-align: center;
}
.form-item{
  &:last-child{
    margin-bottom: 0px;
  }
}

.rate-pass{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  span{
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.01em;
    color: #8B8B8B;
  }
  div{
    width: 70px;
    height: 4px;
    background: $ec-grey;
    border-radius: 17px;
    transition: ease .3s;
    &.red{
      background-color: #FF9B9B;
    }
    &.yellow{
      background-color: #F5E27F
    }
    &.green{
      background-color: #57BA5E
    }
  }
}
</style>
