<template>
  <label class="ec-checkbox">
    <input
      type="checkbox"
      :disabled="disabled"
      :checked="checked"
      @click="onClick"
    >
    <span>
      {{ label }}
      <transition name="default">
        <svg v-if="checked" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M4 0C1.79086 0 0 1.79086 0 4V14C0 16.2091 1.79086 18 4 18H14C16.2091 18 18 16.2091 18 14V4C18 1.79086 16.2091 0 14 0H4ZM5.75258 9.23247C5.3889 8.81684 4.75713 8.77472 4.3415 9.1384C3.92586 9.50208 3.88374 10.1338 4.24742 10.5495L6.055 12.6153C7.16894 13.8884 9.19926 13.7107 10.0752 12.2635L13.8555 6.0178C14.1415 5.54532 13.9903 4.93047 13.5178 4.6445C13.0453 4.35853 12.4305 4.50972 12.1445 4.9822L8.36419 11.2279C8.189 11.5174 7.78294 11.5529 7.56015 11.2983L5.75258 9.23247Z" fill="#424242"/>
        </svg>
      </transition>
    </span>
  </label>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue'

const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  checked: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  }
})
const emit = defineEmits(['clicked'])

const onClick = () => {
  if (!props.disabled) emit('clicked')
}
</script>

<style scoped lang="scss">
label {
  padding-left: 30px;
  cursor: pointer;
}
input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;

  &:disabled ~ span {
    opacity: 0.3;
    pointer-events: none;
    cursor: default;
  }
}
span{
  color: rgba(#2F2F2F, .6);
  position: relative;
  &:before {
    position: absolute;
    left: -30px;
    top: -1px;
    content: '';
    width: 14px;
    height: 14px;
    border: 2px solid #A9A9A9;
    border-radius: 4px;
    transition: ease .3s;
  }
  &:hover{
    svg{
      path{
        fill: $ford-medium-dark;
      }
    }
  }
  svg{
    position: absolute;
    left: -30px;
    top: -1px;
    transition: ease .3s;
    path{
      transition: ease .3s
    }
  }
}
</style>
