import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const Home = () => import('../views/Home.vue')
const About = () => import('../views/About.vue')
const News = () => import('../views/news/News.vue')
const Article = () => import('../views/news/Article.vue')
const Docs = () => import('../views/docs/Docs.vue')
const PaymentHistory = () => import('../views/paymentHistory/PaymentHistory.vue')
const Contacts = () => import('../views/Contacts.vue')
const AdaptiveAccount = () => import('../views/AdaptiveAccount.vue')
const NotFound = () => import('../views/NotFound.vue')
const ContractTKO = () => import('../views/ContractTKO.vue')
const RulesTKO  = () => import('../views/RulesTKO.vue')
const SecondaryResources = () => import('../views/SecondaryResources.vue')
const NoTKOService = () => import('../views/NoTKOService.vue')
const FAQ = () => import('../views/FAQ.vue')
const ContainersPickupSchedule = () => import('../views/ContainersPickupSchedule.vue')


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      routeTitle: 'Главная',
      // isAuth: true,
    },
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: {
      routeTitle: 'О компании',
    },
  },
  {
    path: '/news',
    name: 'News',
    component: News,
    meta: {
      routeTitle: 'Новости',
    },
  },
  {
    path: '/news/:article',
    name: 'Article',
    component: Article,
    meta: {
      routeTitle: 'Новость',
    },
  },
  {
    path: '/contract-tko',
    name: 'ContractTKO',
    component: ContractTKO,
    meta: {
      routeTitle: 'Заключить договор на ТКО',
    },
  },
  {
    path: '/rules-tko',
    name: 'RulesTKO',
    component: RulesTKO,
    meta: {
      routeTitle: 'Правила обращения с ТКО',
    },
  },
  {
    path: '/secondary-resources',
    name: 'SecondaryResources',
    component: SecondaryResources,
    meta: {
      routeTitle: 'Прием вторичных ресурсов',
    },
  },
  {
    path: '/no-tko-service',
    name: 'NoTKOService',
    component: NoTKOService,
    meta: {
      routeTitle: 'Вывоз отходов, не относящихся к ТКО',
    },
  },
  {
    path: '/documents',
    name: 'Docs',
    component: Docs,
    meta: {
      routeTitle: 'Документы',
    },
  },
  {
    path: '/payment-history/:bill',
    name: 'PaymentHistory',
    component: PaymentHistory,
    meta: {
      routeTitle: 'История операций',
    },
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: Contacts,
    meta: {
      routeTitle: 'Контакты',
    },
  },
  {
    path: '/account',
    name: 'Account',
    component: AdaptiveAccount,
    meta: {
      routeTitle: 'Личный кабинет',
    },
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: FAQ,
    meta: {
      routeTitle: 'Часто задаваемые вопросы',
    },
  },
  {
    path: '/containers-pickup-schedule',
    name: 'ContainersPickupSchedule',
    component: ContainersPickupSchedule,
    meta: {
      routeTitle: 'Контейнерные площадки',
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
    meta: {
      routeTitle: '404',
    },
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    // @ts-ignore: Object is possibly 'null'.
    // document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }
})

export default router
