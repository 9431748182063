<template>
  <div class="payment">
    <ECButton
      @click="isShowModalPayment = true"
      label="Пополнить баланс"
    >
      <template v-slot:iconLeft>
        <svg class="slot-icon" width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.5013 2.66602C9.5013 2.11373 9.05359 1.66602 8.5013 1.66602C7.94902 1.66602 7.5013 2.11373 7.5013 2.66602H9.5013ZM7.5013 13.3327C7.5013 13.885 7.94902 14.3327 8.5013 14.3327C9.05359 14.3327 9.5013 13.885 9.5013 13.3327H7.5013ZM3.16797 6.99942C2.61568 6.99942 2.16797 7.44714 2.16797 7.99942C2.16797 8.55171 2.61568 8.99942 3.16797 8.99942V6.99942ZM13.8346 8.99942C14.3869 8.99942 14.8346 8.55171 14.8346 7.99942C14.8346 7.44714 14.3869 6.99942 13.8346 6.99942V8.99942ZM7.5013 2.66602L7.5013 13.3327H9.5013V2.66602H7.5013ZM3.16797 8.99942H13.8346V6.99942L3.16797 6.99942V8.99942Z" fill="white"/></svg>
      </template>
    </ECButton>
  </div>

  <transition name="default">
    <ECModalPayment
      v-if="isShowModalPayment"
      @close="isShowModalPayment = false"
      :debt="debt"
      :billNumber="billNumber"
    />
  </transition>
</template>

<script setup>
import { defineProps, ref } from 'vue'
import ECModalPayment from '@/components/modals/ECModalPayment'
import useApi from '@/services/useApi'
import moment from 'moment'

const props = defineProps({
  billNumber: {
    type: String,
    default: '',
  },
  debt: {
    type: String,
    default: '',
  },
})

const isShowModalPayment = ref(false)
</script>

<style scoped lang="scss">
.slot-icon{
  margin-right: 10px;
}
</style>
