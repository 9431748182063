<template>
  <ECModal
    @close="$emit('close')"
    title="Личный кабинет обновился!"
  >
    <form id="lk-updated" class="content" @submit.prevent="">
      <div class="modal-info">Мы обновили вход в личный кабинет. Дополните информацию о себе, чтобы авторизироваться по-новому</div>

      <div class="form-item">
        <label>Телефон</label>
        <ECInput
          placeholder="+7"
          masked="tel"
          v-model="phone"
        />
      </div>

      <div class="form-item">
        <label>Придумайте пароль</label>
        <ECInput
          v-model="password"
          placeholder=""
          :type="typeOfPasswordInput"
          :show-password="true"
          @switch-show-pass="typeOfPasswordInput === 'password' ? typeOfPasswordInput = 'text' : typeOfPasswordInput = 'password'"
        />
      </div>

      <div class="form-item">
        <label>Повторите пароль</label>
        <ECInput
          v-model="repeatPassword"
          placeholder=""
          type="password"
        />
      </div>

      <div v-if="error" class="error">{{ error }}</div>

      <div class="form-item">
        <ECButton
          label="Далее"
          view="primary"
          :disabled="!validate"
          @click="sendForm"
        />
      </div>
    </form>
  </ECModal>
</template>

<script setup>
import { defineProps, defineEmits, ref, computed } from 'vue'
import useApi from '@/services/useApi'
import formattedPhone from '@/helpers/formattedPhone.js'

const props = defineProps({
  inn: {
    type: String,
    default: ''
  },
  bill: {
    type: String,
    default: ''
  },
  name: {
    type: String,
    default: ''
  },
  loginType: {
    type: Number,
    default: 4
  }
})
const emit = defineEmits(['close', 'setTempPhoneAndOpenConfirmPhone'])

const phone = ref('')
const password = ref('')
const repeatPassword = ref('')
const typeOfPasswordInput = ref('password')
const error = ref('')

const validate = computed(() => phone.value.length === 16  && password.value.length >= 3 && repeatPassword.value === password.value )

const sendForm = async() => {
  if(props.loginType === 1){
    const req = await useApi('post', 'users/registr_by_personal_bill', {
      personal_bill: props.bill,
      name: props.name,
      phone: formattedPhone(phone.value),
      password: password.value,
    })
    if(req.error){
      error.value = req.error
    }
    if(req.status === 'successful'){
      emit('close')
      emit('setTempPhoneAndOpenConfirmPhone', formattedPhone(phone.value))
    }
  } else {
    const req = await useApi('post', 'users/check_by_inn_send_sms', {
      inn: props.inn,
      phone: formattedPhone(phone.value),
      password: password.value,
    })
      if(req.error){
      error.value = req.error
    }
    if(req.status === 'successful'){
      emit('close')
      emit('setTempPhoneAndOpenConfirmPhone', formattedPhone(phone.value))
    }
  }
}
</script>

<style scoped lang="scss">
.error{
  margin: 0 auto;
  margin-bottom: 10px;
  color: #FF7A7A;
  text-align: center;
}
</style>
