import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ECCheckbox from "@/components/ui/ECCheckbox.vue"
import ECButton from "@/components/ui/ECButton.vue"
import ECInput from "@/components/ui/ECInput.vue"
import ECModal from "@/components/ui/ECModal.vue"
import ECSelect from "@/components/ui/ECSelect.vue"
import ECTextarea from "@/components/ui/ECTextarea.vue"

import VueYandexMetrika from 'vue3-yandex-metrika'


const app = createApp(App)

app.use(store)
app.use(router)

app.use(VueYandexMetrika, {
  id: 94064437,
  env: process.env.NODE_ENV
  // env: 'production'
})

app.component('ECCheckbox', ECCheckbox)
app.component('ECButton', ECButton)
app.component('ECInput', ECInput)
app.component('ECModal', ECModal)
app.component('ECSelect', ECSelect)
app.component('ECTextarea', ECTextarea)

app.mount('#app')