<template>
  <transition name="default">
    <div
      class="ec-modal"
      @mousedown="$emit('close')"
    >
      <div
        @mousedown.stop
        class="ec-modal__window"
        :style="{width: widthContent ? 'fit-content' : '343px'}"
      >

        <div class="ec-modal__content">
          <div class="ec-modal__close-btn" @click="$emit('close')">
            <ECRoundButton />
          </div>
          <h3 v-if="title.length" class="ec-modal__title">{{ title }}</h3>
          <slot />
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { defineProps, defineEmits, onMounted, onUnmounted, ref } from 'vue'
import ECRoundButton from '@/components/common/ECRoundButton.vue'

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  widthContent: {
    type: Boolean,
    default: false
  },
  cache: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['close'])

const position = ref(0)

onMounted(()=> {
  if(!props.cache){
    document.addEventListener('keyup', function (e) {
      if(e.keyCode === 27){
        emit('close')
      }
    })

    position.value = window.scrollY
    document.body.style.top = `-${window.scrollY}px`
    document.body.style.position = 'fixed'
  }
})

onUnmounted(()=> {
  document.body.style.position = ''
  document.body.style.top = ''
  window.scrollTo({top: position.value})
})
</script>

<style scoped lang="scss">
  .ec-modal{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba($primary-font-color, .7);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;

    &__window{
      background: white;
      border-radius: 24px;
      position: relative;
      max-height: calc(100vh - 60px);
      padding: 16px 6px 16px 16px;
      overflow: hidden;
    }

    &__title{
      font-size: 22px;
      line-height: 26.36px;
      max-width: 310px;
      text-align: center;
      margin: 0 auto 18px auto;
      text-align: center;
      font-weight: normal;
    }

    &__content{
      width: 100%;
      max-height: calc(100vh - 100px);
      overflow-y: auto;
      padding-right: 10px;
      padding-top: 34px;
      position: relative;
    }

    &__close-btn{
      position: absolute;
      right: 10px;
      top: 0px;
    }
  }
</style>
