<template>
  <div
    class="q-select"
    ref="target"
    :class="{
      filter,
      sort,
      black,
      top
    }"
  >
    <div
      class="q-select__label"
      @click="isOpenSelect = !isOpenSelect"
      v-if="sort"
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.3418 11.2528C20.7576 11.6164 21.3893 11.574 21.7528 11.1582C22.1164 10.7424 22.074 10.1107 21.6582 9.74715L20.3418 11.2528ZM16.9967 7L17.6548 6.24715C17.2777 5.91741 16.7147 5.91764 16.3378 6.2477L16.9967 7ZM15.9967 17C15.9967 17.5523 16.4444 18 16.9967 18C17.5489 18 17.9967 17.5523 17.9967 17H15.9967ZM12.3412 9.7477C11.9257 10.1116 11.8838 10.7433 12.2477 11.1588C12.6116 11.5743 13.2433 11.6162 13.6588 11.2523L12.3412 9.7477ZM3 8C2.44772 8 2 8.44772 2 9C2 9.55228 2.44772 10 3 10V8ZM7 10C7.55228 10 8 9.55228 8 9C8 8.44772 7.55228 8 7 8V10ZM3 12C2.44772 12 2 12.4477 2 13C2 13.5523 2.44772 14 3 14V12ZM9.5 14C10.0523 14 10.5 13.5523 10.5 13C10.5 12.4477 10.0523 12 9.5 12V14ZM3 16.0714C2.44772 16.0714 2 16.5191 2 17.0714C2 17.6237 2.44772 18.0714 3 18.0714V16.0714ZM12 18.0714C12.5523 18.0714 13 17.6237 13 17.0714C13 16.5191 12.5523 16.0714 12 16.0714V18.0714ZM21.6582 9.74715L17.6548 6.24715L16.3385 7.75285L20.3418 11.2528L21.6582 9.74715ZM15.9967 7V17H17.9967V7H15.9967ZM16.3378 6.2477L12.3412 9.7477L13.6588 11.2523L17.6555 7.7523L16.3378 6.2477ZM3 10H7V8H3V10ZM3 14H9.5V12H3V14ZM3 18.0714H12V16.0714H3V18.0714Z" fill="#C6C6C6"/>
      </svg>
    </div>
    <div
      v-else
      class="q-select__label"
      :class="{clearValue}"
      @click="isOpenSelect = !isOpenSelect"
    >
      <span>{{selectedItem.label}}</span>
      <img
        src="@/assets/images/arrow-grey.svg"
        alt="открыть/закрыть селект"
        class="q-select__arrow"
        :class="{active: isOpenSelect}"
      >
    </div>
    <transition name="up">
      <div
        class="q-select__list"
        v-if="isOpenSelect"
      >
        <ul :style="{maxHeight: maxHeight+'px'}">
          <li
            class="q-select__item"
            v-for="item in items"
            :key="item.value"
            @click="setValue(item)"
            :class="{active: selectedItem.label === item.label}"
          >
            {{ item.label }}
          </li>

          <li
            class="q-select__item q-select__item--empty"
            v-if="emptyMessage.length"
            @click="setValue({id: '', label: 'Не выбрано'})"
          >
            {{emptyMessage}}
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, ref } from 'vue'
import { onClickOutside } from '@vueuse/core'

const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
  selectedItem: {
    type: Object,
  },
  maxHeight: {
    type: Number,
  },
  filter: {
    type: Boolean,
    default: false
  },
  sort: {
    type: Boolean,
    default: false
  },
  black: {
    type: Boolean,
    default: false
  },
  top: {
    type: Boolean,
    default: false
  },
  clearValue: {
    type: Boolean,
    default: false
  },
  emptyMessage: {
    type: String,
    default: ''
  }

})
const emit = defineEmits(['changeSelect'])

const isOpenSelect = ref(false)

const target = ref(null)

onClickOutside(target, (event) => isOpenSelect.value = false)

const setValue = item => {
  isOpenSelect.value = false
  emit('changeSelect', item)
}
</script>

<style scoped lang="scss">
.q-select{
  position: relative;

  &__label{
    height: 40px;
    background: $ec-grey;
    padding: 8px 40px 8px 18px;
    border-radius: 14px;
    cursor: pointer;
    span {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &.clearValue{
      padding: 8px 60px 8px 18px;
    }
  }

  &__arrow{
    position: absolute;
    right: 12px;
    top: 13px;
    transition: ease .2s;

    &.active{
      transform: rotate(180deg);
    }
  }

  &__list{
    position: absolute;
    top: 45px;
    right: 0;
    width: 100%;
    padding: 16px 8px;
    background: $ec-grey;
    border-radius: 22px;
    z-index: 2;
    background: white;
    filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.1));
    ul{
      overflow-y: auto;
    }
  }
  &__item{
    overflow: hidden;
    margin-bottom: 0px;
    padding: 8px 10px;
    border-radius: 14px;
    font-size: 14px;
    transition: ease .3s;
    cursor: pointer;
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
    &:last-child{
      margin-bottom: 0;
    }
    &--empty{
      color: $ec-red;
    }
    &.active{
      opacity: .3;
      &:before{
        content: '';
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: rgba($ford-medium-dark, .3);
      }
    }
    &:hover{
      background: $ec-grey
    }
  }


  .up-enter-active,
  .up-leave-active {
    transition: .2s ease;
  }

  .up-enter-from,
  .up-leave-to {
    transform: translateY(-20px);
    opacity: 0;
  }
}

.top{
  .q-select__list{
    top: auto;
    bottom: 12px;
    background: white;
    width: 80px;
    filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.1));

    .q-select__item{
      text-align: center;
      margin-right: 7px;
      &.active{
        &:before{
          display: none;
        }
        opacity: 1;
        background: $ec-grey;
      }
    }
  }
}

.filter{
  .q-select__label{
    height: 48px;
    padding: 12px 40px 8px 18px;
    background: rgba(white, .1);
    span{
      color: white;
      font-size: 16px;
    }
    .q-select__arrow{
      right: 17px;
      top: 17px;
    }
  }
  .q-select__list{
    bottom: 70px;
    top: auto;
    background: $ford-medium-dark;

    .q-select__item{
      font-size: 16px;
      color: white;
      margin-bottom: 10px;
    }
    .active:before{
      background: rgba(white, .3)
    }
  }
}

.sort {
  .q-select__label{
    padding: 0;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: blue;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .q-select__list{
    width: 240px;
  }
}


.black{
  .q-select__label{
    padding: 9px 40px 8px 18px;
    background: $ford-medium-dark;
    span{
      color: white;
      font-size: 16px;
    }
  }
  .q-select__list{
    background: $ford-medium-dark;
    z-index: 12;

    .q-select__item{
      font-size: 16px;
      color: white;
    }
    .active:before{
      background: rgba(white, .3)
    }
  }
}

.clear-value{
  position: absolute;
  right: 40px;
  top: 11px;
  cursor: pointer;
}
</style>
