export default function handlePhoneToDefault(tel) {
  const handl = tel.split('')
    .filter(s => s !== '(')
    .filter(s => s !== ')')
    .filter(s => s !== ' ')
    .filter(s => s !== '-')
    .filter(s => s !== '_')
    .filter(s => s !== '.')
    .join('')

  return handl
}
