<template>
  <div class="create-account">
    <h4>Завести личный кабинет</h4>

    <h6>Управляйте лицевыми счетами и оплачивайте услуги</h6>

    <form id="create-account" @submit.prevent="">
      <div class="form-item">
        <label>Телефон</label>
        <ECInput
          placeholder="+7"
          masked="tel"
          v-model="phone"
        />
      </div>

      <transition name="default">
        <div v-if="isPhoneExist" class="form-item try-login">
          Личный кабинет по такому номеру уже существует. <span @click="goLogin">Попробуйте войти</span>
        </div>
      </transition>

      <ECButton
        label="Зарегистрироваться"
        view="primary"
        :disabled="phone.length !== 16"
        @click="sendForm"
      />
    </form>
  </div>

  <transition name="default">
    <ECModalConfirmPhone
      v-if="isShowModalConfirmPhone"
      @close="isShowModalConfirmPhone = false"
      :phone="formattedPhone(phone)"
      @successCode="isShowModalCreatePassword = true"
    />
  </transition>

  <transition name="default">
    <ECModalCreatePassword
      v-if="isShowModalCreatePassword"
      @close="isShowModalCreatePassword = false"
      :phone="formattedPhone(phone)"
    />
  </transition>

  <transition name="default">
    <ECModalLogin
      v-if="isShowModalLogin"
      @close="isShowModalLogin  = false"
      :currentSwitcherItem="0"
      hideSwitcher
      @closeAndOpenRecovery="closeAndOpenRecovery"
    />
  </transition>

  <transition name="default">
    <ECModalPasswordRecovery
      v-if="isShowEodalPasswordRecovery"
      @close="isShowEodalPasswordRecovery = false"
      @successful="closeAndOpenConfirmSms"
    />
  </transition>

  <transition name="default">
    <ECModalConfirmPhone
      v-if="isShowModalConfirmPhoneThenRecovery"
      @close="closeAndSetPassword"
      :phone="formattedPhone(phone)"
      :loginType="3"
    />
  </transition>

  <transition name="default">
    <ECModalCreatePasswordRecovery
      v-if="isShowModalCreatePasswordRecovery"
      @close="isShowModalCreatePasswordRecovery = false"
      :phone="formattedPhone(phone)"
      type="forgot"
    />
  </transition>
</template>

<script setup>
import { defineEmits, ref } from 'vue'
import useApi from '@/services/useApi'
import formattedPhone from '@/helpers/formattedPhone.js'
import store from '@/store'
import ECModalLogin from '@/components/modals/ECModalLogin.vue'
import ECModalConfirmPhone from '@/components/modals/ECModalConfirmPhone.vue'
import ECModalCreatePassword from '@/components/modals/ECModalCreatePassword.vue'
import ECModalPasswordRecovery from '@/components/modals/ECModalPasswordRecovery.vue'
import ECModalConfirmPhoneRecovery from '@/components/modals/ECModalConfirmPhone.vue'
import ECModalCreatePasswordRecovery from '@/components/modals/ECModalCreatePassword.vue'
import { useRouter } from 'vue-router'

const emit = defineEmits(['close', 'successful', 'autopayOff'])

const phone = ref('')
const isShowModalConfirmPhone = ref(false)
const isShowModalCreatePassword = ref(false)
const isShowEodalPasswordRecovery = ref(false)
const isShowModalConfirmPhoneThenRecovery = ref(false)
const isShowModalCreatePasswordRecovery = ref(false)
const isPhoneExist = ref(false)

const router = useRouter()

const isShowModalLogin = ref(false)

const sendForm = async() => {
  const req = await useApi('post', 'users/registr', {
    phone: formattedPhone(phone.value),
  })
  if(req.error){
    isPhoneExist.value = true
    return
  }
  if(req.status === 'successful'){
    isPhoneExist.value = false
    isShowModalConfirmPhone.value = true
  }
}

const closeAndOpenRecovery = () => {
  isShowModalLogin.value = false
  isShowEodalPasswordRecovery.value = true
}

const closeAndOpenConfirmSms = () => {
  isShowEodalPasswordRecovery.value = false
  isShowModalConfirmPhoneThenRecovery.value = true
}

const closeAndSetPassword = () => {
  isShowModalConfirmPhoneThenRecovery.value = false
  isShowModalCreatePasswordRecovery.value = true
}

const goLogin = () => {
  router.push({ query: {tel: formattedPhone(phone.value)}})
  setTimeout(() => {
    isShowModalLogin.value = true
  }, 0);
}
</script>

<style scoped lang="scss">
  .create-account{
    h4{
      margin-top: 15px;
      margin-bottom: 5px;
      margin-left: 16px;
    }
    h6{
      margin-bottom: 20px;
      margin-left: 16px;
      color: $ec-green;
      font-weight: 500;
      font-size: 16px;
    }
    form{
      background: white;
      padding: 16px;
      border-radius: 22px;
    }
  }
  .try-login{
    color: rgba(22, 22, 22, 0.5);
    span{
      color: $ec-green;
      cursor: pointer;
    }
  }
</style>
