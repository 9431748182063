import axios from 'axios'
import store from '@/store'

export default function useApi(method, url, body) {
  const domain = process.env.VUE_APP_BASE_API

  const headers = {
    headers: {
      'Authorization': `${store.state.user.token}`,
    }
  }

  if(method === 'get'){
    return axios.get(`${domain}/${url}`, headers)

    .then((response)=> {
      // if(response.data.error){
      //   store.dispatch('logout')
      //   document.location.reload()
      //   return
      // } else {
        return response.data
      // }
    })
  }

  if(method === 'post'){
    return axios.post(`${domain}/${url}`, body, headers)

    .then((response)=> {
    //   if(response.data.error === 'invalid auth token'){
    //     store.dispatch('logout')
    //     document.location.reload()
    //   } else {
        return response.data
      // }
    })
  }

  if(method === 'patch'){
    return axios.post(`${domain}/${url}`, body, headers)

    .then((response)=> {
    //   if(response.data.error === 'invalid auth token'){
    //     store.dispatch('logout')
    //     document.location.reload()
    //   } else {
        return response.data
      // }
    })
  }

  if(method === 'delete'){
    return axios.delete(`${domain}/${url}`, headers)

    .then((response)=> {
      // if(response.data.error === 'invalid auth token'){
      //   store.dispatch('logout')
      //   document.location.reload()
      // } else {
      //   return response.data
      // }
      return response.data
    })
  }
}
