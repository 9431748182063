<template>
  <ECModal
    @close="$emit('close')"
    title=""
  >
    <form id="auto-pay-status" class="content" @submit.prevent="">
      <svg v-if="status === 'success'"  width="39" height="38" viewBox="0 0 39 38" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="19.5" cy="19" r="17.3333" stroke="#57BA5E" stroke-width="2.66667"/><path fill-rule="evenodd" clip-rule="evenodd" d="M13.7251 19.4135C13.0785 18.6746 11.9554 18.5998 11.2165 19.2463C10.4776 19.8929 10.4027 21.016 11.0493 21.7549L14.2627 25.4274C16.2431 27.6907 19.8525 27.3749 21.4097 24.8021L28.1303 13.6986C28.6387 12.8586 28.3699 11.7656 27.5299 11.2572C26.69 10.7488 25.5969 11.0175 25.0885 11.8575L18.368 22.961C18.0565 23.4756 17.3346 23.5387 16.9386 23.0861L13.7251 19.4135Z" fill="#57BA5E"/></svg>
      <svg v-else width="39" height="38" viewBox="0 0 39 38" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="19.5" cy="19" r="17.3333" stroke="#FE5F5F" stroke-width="2.66667"/><path fill-rule="evenodd" clip-rule="evenodd" d="M11.891 11.391C12.4124 10.8697 13.2576 10.8697 13.779 11.391L19.835 17.447L25.891 11.391C26.4124 10.8697 27.2576 10.8697 27.779 11.391C28.3003 11.9124 28.3003 12.7576 27.779 13.279L21.723 19.335L27.779 25.391C28.3003 25.9124 28.3003 26.7576 27.779 27.279C27.2576 27.8003 26.4124 27.8003 25.891 27.279L19.835 21.223L13.779 27.279C13.2576 27.8003 12.4124 27.8003 11.891 27.279C11.3697 26.7576 11.3697 25.9124 11.891 25.391L17.947 19.335L11.891 13.279C11.3697 12.7576 11.3697 11.9124 11.891 11.391Z" fill="#FE5F5F"/></svg>

      <div class="title">Автоплатеж подключен!</div>

      <div class="modal-info">Вы можете управлять автоплатежем в меню лицевого счета</div>
    </form>
  </ECModal>
</template>

<script setup>
import { onUnmounted, computed, defineEmits } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const emit = defineEmits(['close'])
const route = useRoute()
const router = useRouter()

const status = computed(()=> route.query.autosave_status)

onUnmounted(() => {
  router.push({ query: null})
})
</script>

<style scoped lang="scss">
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title{
  font-size: 22px;
  margin-top: 18px;
  margin-bottom: 20px;
  text-align: center;
}
</style>
