<template>
  <ECModal
    @close="$emit('close')"
    title="Оплатить услуги"
  >
    <form id="payment" class="content" @submit.prevent="">

      <div class="modal-info">Введите сумму</div>

      <ECInput
        placeholder=""
        v-model="summ"
        type="number"
        green
        price
      />

      <br/>

      <div class="form-item">
        <ECButton
          @click="pay"
          label="Оплатить"
          :disabled="!validate"
        />
      </div>

      <ECButtonYandexPay
        :disabled="false"
        @pay="pay({yandexPay: true})"
      />
    </form>
  </ECModal>
</template>

<script setup>
import { onMounted, ref, computed, defineProps, defineEmits } from 'vue'
import ECButtonYandexPay from '@/components/ui/ECButtonYandexPay.vue'
import useApi from '@/services/useApi'

const props = defineProps({
  billNumber: {
    type: String,
    default: '',
  },
  debt: {
    type: String,
    default: '',
  }
})
const emit = defineEmits(['close'])

const summ = ref('')

const pay = async(type) => {
  const req = await useApi('post', type.yandexPay ? 'payment/yapay/create' : 'payment/sber/auth', {
    amount: +summ.value,
    bill_number: props.billNumber
  })
  if(req.status === 'successful'){
    window.location.assign(req.url)
  }
}

onMounted(()=> {
  if(Number(props.debt) < 0){
    const m = props.debt.split('')
    m.shift()
    const r = m.join('')
    summ.value = r
  }

  document.addEventListener('keyup', function (e) {
    if(e.keyCode === 13 && validate.value) pay()
  });
})

const validate = computed(()=> summ.value[0] && summ.value[0] !== '0')
</script>

<style scoped lang="scss"></style>
