<template>
  <div class="cookie-popap" :class="{hidden}">
    <img src="@/assets/images/bell.svg">
    <p>Продолжая работу с сайтом, Вы соглашаетесь с использованием файлов cookie.
      <br/>
      <a class="link" target="_blank" href="/docs/Politika obrabotki personalnykh dannykh.pdf">Политика конфиденциальности</a>
    </p>
    <button @click="setPolicyCookie">Понятно</button>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import useApi from '@/services/useApi'
import store from '@/store'

const hidden = ref(false)

const setPolicyCookie = async() => {
  if(store.state.user.token){
    const req = await useApi('post', 'users/policy', {
      token: store.state.user.token
    })
    if(req.status === 'successful'){
      store.dispatch('setPolicy')
    }
  }

  hidden.value = true
  localStorage.setItem('ec-user-policy-cookie', true)
}
</script>

<style scoped lang="scss">
.cookie-popap{
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 18px;
  background: white;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 22px;
  display: flex;
  align-items: center;
  transition: ease .3s;
  img{
    margin-right: 20px;
  }
  p{
    font-size: 12px;
    line-height: 130%;
    a{
      text-decoration: none;
      font-size: 12px;
      line-height: 130%;
      color: $ec-green;
    }
  }
  button{
    margin-left: 20px;
    padding: 5px;
    color: $ec-green;
  }
  &.hidden{
    bottom: -150px;
    opacity: 0;
    visibility: hidden;
  }
}
</style>
