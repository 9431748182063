<template>
  <ECModal
    @close="$emit('close')"
    title="Редактирование профиля"
  >
    <form id="edit-user-info" class="content" @submit.prevent="">
      <div class="form-item">
        <label>ФИО</label>
        <ECInput
          placeholder="Введите ФИО"
          v-model="name"
          maxLength="127"
        />
      </div>
      <div class="form-item">
        <label>Телефон</label>
        <ECInput
          placeholder="+7"
          masked="tel"
          v-model="phone"
        />
      </div>
      <div class="form-item">
        <label>E-mail</label>
        <ECInput
          placeholder="Введите E-mail"
          v-model="email"
          maxLength="127"
        />
      </div>

      <div class="form-item form-item--small-margin">
        <ECButton
          label="Сохранить"
          view="primary"
          @click="saveUserData"
          :disabled="!validate"
        />
      </div>

      <ECButton
        label="Сменить пароль"
        view="grey"
        @click="changePass"
      />
    </form>
  </ECModal>
</template>

<script setup>
import { ref, onMounted, computed, defineEmits } from 'vue'
import store from '@/store'
import useApi from '@/services/useApi'
import formattedPhone from '@/helpers/formattedPhone.js'

const emit = defineEmits(['close', 'successful', 'saveUserData'])

const phone = ref('')
const name = ref('')
const email = ref('')

onMounted(()=> {
  name.value = store.state.user.userName
  phone.value = store.state.user.userPhone
  email.value = store.state.user.userEmail
})

const validate = computed(() => name.value.length > 4 && phone.value.length === 16 && email.value.includes('@') && email.value.includes('.'))

const saveUserData = async() => {
  const payload = {
    email: email.value,
    name: name.value,
    phone: formattedPhone(phone.value)
  }
  emit('saveUserData', payload)
  emit('close')
}

const changePass = async() => {
  const req = await useApi('post', 'users/forgot/send_sms', {
    phone: formattedPhone(phone.value)
  })
  emit('close')
  emit('successful', formattedPhone(phone.value))
}
</script>

<style scoped lang="scss">
.form-item--small-margin{
  margin-bottom: 10px;
}
// .content{
//   padding-bottom: 30px;
// }
</style>
