import { createStore } from 'vuex'

export default createStore({
  state: {
    user: {
      token: localStorage.getItem('ec-token') || null,
      userPhone: localStorage.getItem('ec-user-phone') || '',
      userName: localStorage.getItem('ec-user-name') || '',
      userEmail: localStorage.getItem('ec-user-email') || ''
    },
    globalPreloader: false,
    modalFailRequest: false,

    commonContactsData: null,

    policy: false,
  },
  mutations: {
    setUsersData(state, data) {
      state.user.token = data.token
      localStorage.setItem('ec-token', data.token)
    },

    setUsersInfo(state, data){
      state.user.userPhone = data.phone
      state.user.userName = data.name
      state.user.userEmail = data.email

      localStorage.setItem('ec-user-phone', data.phone)
      localStorage.setItem('ec-user-name', data.name)
      localStorage.setItem('ec-user-email', data.email)
    },

    logoutUser(state) {
      state.user.token = null
      localStorage.removeItem('ec-token')
      localStorage.removeItem('ec-user-phone')
      localStorage.removeItem('ec-user-name')
      localStorage.removeItem('ec-user-email')
    },

    setGlobalPreloader(state, data){
      state.globalPreloader = data
    },

    setModalFailRequest(state, data){
      state.modalFailRequest = data
    },

    setCommonContactsData(state, data){
      state.commonContactsData = data
    },
    setPolicy(state){
      state.policy = true
    },
  },
  actions: {
    login({commit}, data) {
      commit('setUsersData', data)
      commit('setUsersInfo', {
        name: data.user_info.name,
        phone: data.user_info.phone,
        email: data.user_info.email,
      })
    },
    setUsersInfo({commit}, data){
      commit('setUsersInfo', data)
    },
    logout({commit}){
      commit('logoutUser')
    },
    setGlobalPreloader({commit}, data){
      commit('setGlobalPreloader', data)
    },
    setModalFailRequest({commit}, data){
      commit('setModalFailRequest', data)
    },
    setCommonContactsData({commit}, data){
      commit('setCommonContactsData', data)
    },
    setPolicy({commit}, data){
      commit('setPolicy')
    },
  },
  getters: {
    // getUserToken: state => state.user.token,
  }
})
