<template>
  <ECModal
    @close="$emit('close')"
    title="Упс... У нас нет Вашего email! "
  >
    <form id="no-email" class="content" @submit.prevent="">

      <div class="modal-info">Пожалуйста, зарегистрируйтесь по номеру телефона</div>

      <div class="form-item">
        <label>Телефон</label>
        <ECInput
          placeholder="+7"
          masked="tel"
          v-model="phone"
          :error="phoneError"
        />
      </div>

      <div class="form-item">
        <ECButton
          label="Зарегистрироваться"
          view="primary"
          :disabled="phone.length !== 16"
          @click="sendForm"
        />
      </div>
    </form>
  </ECModal>
</template>

<script setup>
import { defineEmits, ref } from 'vue'
import useApi from '@/services/useApi'
import formattedPhone from '@/helpers/formattedPhone.js'

const emit = defineEmits(['close', 'setTempPhoneAndOpenConfirmSms'])

const phone = ref('')
const phoneError = ref('')

const sendForm = async() => {
  const req = await useApi('post', 'users/registr', {
    phone: formattedPhone(phone.value),
  })
  if(req.error){
    phoneError.value = req.error
    return
  }
  if(req.status === 'successful'){
    phoneError.value = ''
    emit('close')
    emit('setTempPhoneAndOpenConfirmSms', formattedPhone(phone.value))
  }
}
</script>

<style scoped lang="scss">.error{
  margin: 0 auto;
  margin-bottom: 10px;
  color: #FF7A7A;
  text-align: center;
}
</style>
