<template>
  <div class="adaptive-block">

    <div class="adaptive-block__user-btn">
      <div @click="$store.state.user.token ? $router.push('/account') : $emit('login')">
        <img class="icon" :src="require(`@/assets/images/adapt-user${$route.path === '/account' ? '-green' : ''}.svg`)">
      </div>

      <div class="devider"/>
    </div>

    <div class="adapt-menu" ref="target">
      <img
        class="icon"
        @click="isAdaptiveMenu = !isAdaptiveMenu"
        :src="require(`@/assets/images/adapt-menu${isAdaptiveMenu ? '-close' : ''}.svg`)"
      >

      <div @click="isAdaptiveMenu = false" :class="{show: isAdaptiveMenu}" class="adapt-menu-background"/>

      <div :class="{show: isAdaptiveMenu}" class="adapt-menu-block">
        <div class="adapt-menu-block__header">
          <a href="tel:+73472980490">+7 (347) 298 04 90</a>
          <div class="devider"/>
          <div class="login" @click="handleLogin">
            {{ $store.state.user.token ? 'Выйти' : 'Войти' }}
          </div>
        </div>

        <div class="adapt-menu-block__links">
          <router-link to="/">Главная</router-link>
          <router-link to="/news">Новости</router-link>
          <router-link to="/about">О компании</router-link>
          <router-link to="/documents">Документы</router-link>
          <router-link to="/contacts">Контакты</router-link>
          <router-link to="/containers-pickup-schedule">Контейнерные площадки</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, defineEmits } from 'vue';
import store from '@/store'
import { useRoute } from 'vue-router'
import { onClickOutside } from '@vueuse/core'

const emit = defineEmits(['login'])
const route = useRoute()

const target = ref(null)
const isAdaptiveMenu = ref(false)

watch(() => route.path, () => isAdaptiveMenu.value = false)

const handleLogin = () => {
  store.state.user.token ? store.dispatch('logout') : emit('login')
  isAdaptiveMenu.value = false
}

onClickOutside(target, (event) => isAdaptiveMenu.value = false)

const position = ref(0)

watch(() => isAdaptiveMenu.value, () => {
  if(isAdaptiveMenu.value){
    position.value = window.scrollY
    document.body.style.top = `-${window.scrollY}px`
    document.body.style.position = 'fixed'
  } else {
    document.body.style.position = ''
    document.body.style.top = ''
    window.scrollTo({top: position.value})
  }
})
</script>

<style scoped lang="scss">
  .adaptive-block{
    padding: 12px 14px;
    background: #F6F6F6;
    border-radius: 16px;
    display: flex;
    align-items: center;
    display: none;

    &__user-btn{
      display: flex;
      align-items: center;
      display: none;
      cursor: pointer;
      @media (max-width: 740px){
        display: flex;
      }
    }

    .green:v-deep svg * {
      fill: red
    }
    @media (max-width: 1100px){
      display: flex;
    }
  }
  .devider{
    width: 2px;
    height: 16px;
    background: rgba(169, 169, 169, 0.4);
    border-radius: 2px;
    margin: 0 14px;
  }

  .adapt-menu{
    position: relative;
    width: 24px;
    height: 24px;

    .icon{
      cursor: pointer;
    }
    &-background{
      position: fixed;
      right: 0;
      top: 24px;
      width: 100vw;
      position: fixed;
      right: 0;
      top: 74px;
      width: 100vw;
      height: calc(100vh - 74px);
      background-color: rgba($primary-font-color, .7);
      opacity: 0;
      visibility: hidden;
      transition: ease .3s;
      &.show{
        visibility: visible;
        opacity: 1;
      }
    }
    &-block{
      position: fixed;
      right: 0;
      top: 24px;
      width: 100vw;
      background: white;
      border-radius: 0 0 10px 10px;
      z-index: 3;
      padding: 25px;
      visibility: hidden;
      opacity: 0;
      transition: ease .3s;
      &__header{
        display: flex;
        align-items: center;
        justify-content: center;
        a{
          text-decoration: none;
          font-size: 16px;
        }
        .login{
          color: #57BA5E;
          font-size: 18px;
          line-height: 22px;
          cursor: pointer;
        }
      }
      &__links{
        overflow: auto;
        display: flex;
        flex-direction: column;
        margin-top: 35px;
        max-height: calc(100vh - 180px);
        a{
          margin-bottom: 30px;
          text-decoration: none;
          font-size: 16px;
          &:last-child{
            margin-bottom: 0;
          }
        }
      }
      &.show{
        visibility: visible;
        opacity: 1;
        transform: translateY(50px)
      }
    }
  }

  .icon{
    width: 24px;
    height: 24px;
    display: block;
  }
</style>
