<template>
  <ECModal
    @close="$emit('close')"
    :title="successSended ? '' : 'Заявка на доп. вывоз'"
    cache
  >
    <form id="waste-disposal" v-if="!successSended" class="content" @submit.prevent="">
      <div class="form-item">
        <label class="required">Контактное лицо</label>
        <ECInput
          placeholder="Введите свое имя"
          v-model="name"
          maxLength="127"
        />
      </div>

      <!-- <div class="form-item">
        <label class="required">Адрес</label>
        <ECInput
          placeholder="Введите свой адрес"
          v-model="address"
        />
      </div> -->

      <div class="form-item">
        <label class="required">Адрес оказания услуги</label>
        <ECInput
          v-model="address"
          placeholder="Введите свой адрес"
          required
        />
        <transition name="default">
          <div class="dadata dadata--address" v-if="daDataArrAddress.length && isShowDaDataMenuAddress">
            <div class="dadata-item"
              v-for="item in daDataArrAddress"
              :key="item.value"
              @click="setDaDataAddress(item)"
            >
              <span>{{item.value}}</span>
            </div>
          </div>
        </transition>
      </div>

      <div class="form-item">
        <label>Лицевой счет</label>
        <ECSelect
          :items="selectItems"
          @changeSelect="selectItemSelected = $event"
          :selectedItem="selectItemSelected"
          clearValue
          :emptyMessage="!selectItems.length ? 'Вы еще не добавили ни один ЛС' : ''"
        />
        <svg v-if="selectItemSelected.id != ''" @click="selectItemSelected = {id: '', label: 'Не выбрано'}" class="clear-value" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6569 0.343378C12.0474 0.733902 12.0474 1.36707 11.6569 1.75759L7.41415 6.0003L11.6568 10.2429C12.0473 10.6335 12.0473 11.2666 11.6568 11.6572C11.2663 12.0477 10.6331 12.0477 10.2426 11.6572L5.99993 7.41451L1.75736 11.6571C1.36684 12.0476 0.73367 12.0476 0.343146 11.6571C-0.0473788 11.2666 -0.0473781 10.6334 0.343146 10.2429L4.58572 6.0003L0.343078 1.75766C-0.0474466 1.36713 -0.0474455 0.733969 0.343078 0.343445C0.733603 -0.0470792 1.36677 -0.0470789 1.75729 0.343445L5.99993 4.58609L10.2426 0.343378C10.6332 -0.0471467 11.2663 -0.047147 11.6569 0.343378Z" fill="#C6C6C6"/>
        </svg>
      </div>

      <div class="form-item">
        <label class="required">E-mail</label>
        <ECInput
          placeholder="client@company.ru"
          v-model="email"
          maxLength="127"
        />
      </div>

      <div class="form-item">
        <label class="required">Телефон</label>
        <ECInput
          placeholder="+7"
          masked="tel"
          v-model="phone"
        />
      </div>

      <div class="form-item">
        <label class="required">Примерный объем отходов</label>
        <ECInput
          placeholder="Введите в кубометрах"
          v-model="capacity"
          type="number"
        />
      </div>

      <div class="form-item send">
        <ECButton
          label="Отправить"
          view="primary"
          @click="sendForm"
          :disabled="!validate"
        />
      </div>
    </form>

    <transition name="quick-hide">
      <ECSuccessSendedRequest
        v-if="successSended"
        :id="successSended"
      />
    </transition>
  </ECModal>
</template>

<script setup>
import { defineEmits, ref, onMounted, computed, watchEffect } from 'vue'
import useApi from '@/services/useApi'
import ECSuccessSendedRequest from '@/components/common/ECSuccessSendedRequest.vue'
import formattedPhone from '@/helpers/formattedPhone.js'
import store from '@/store'

const successSended = ref('')

const name = ref('')
const address = ref('')
const email = ref('')
const phone = ref('')
const capacity = ref('')

const daDataArrAddress = ref([])
const isShowDaDataMenuAddress = ref(false)

const selectItemSelected = ref({
  id: '',
  label: 'Не выбрано'
})

const selectItems = ref([])

onMounted(async()=> {
  email.value = store.state.user.userEmail || ''
  phone.value = store.state.user.userPhone || ''


  if(store.state.user.token){
    const req = await useApi('get', 'bills')

    selectItems.value = req.bills.map(item => {
      return {
        id: item.number,
        label: item.number
      }
    })
  }
})

const sendForm = async () => {
  const req = await useApi('post', 'requests/garbage_removal', {
    name: name.value,
    addr: address.value,
    email: email.value,
    bill_number: selectItemSelected.value.id,
    phone: formattedPhone(phone.value),
    volume: capacity.value
  })

  if(req.status === "successful"){
    successSended.value = req.request_id
  }
}

const flagDadata = ref(false)

const setDaDataAddress = (x) => {
  address.value = x.value
  isShowDaDataMenuAddress.value = false
  flagDadata.value = false
}

watchEffect(async() => {
  if(address.value.length >= 3 && flagDadata.value){
    const url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address"
    const token = "f94a30447c5eaf823177a205adbcd6df33603ba9"
    const options = {
      method: "POST",
      mode: "cors",
      headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": "Token " + token
      },
      body: JSON.stringify({
        query: address.value,
        restrict_value: true,
        locations: [{kladr_id: "0200000000000"}],
        locations_boost: [{kladr_id: "7800000000000"}],
        count: 10
      })
    }
    const data = await fetch(url, options)
    const dd = await data.json()
    daDataArrAddress.value = dd.suggestions

    isShowDaDataMenuAddress.value = true
  } else {
    daDataArrAddress.value = []
  }
  flagDadata.value = true
})

const validate = computed(()=>
  name.value.length >= 2 &&
  phone.value.length === 16 &&
  email.value.includes('@') &&
  email.value.includes('.') &&
  address.value.length >= 5 &&
  capacity.value.length >= 1
)
</script>

<style scoped lang="scss">
.clear-value{
  position: absolute;
  right: 35px;
  bottom: 13px;
  cursor: pointer;
}
</style>
