<template>
  <ECModal
    @close="$emit('close')"
    :title="successSended ? '' : 'Обращение к РО'"
    cache
  >
    <transition name="quick-hide">
      <form id="support" v-if="!successSended" class="content" @submit.prevent="">
        <div v-if="!isAuthorized" class="form-item">
          <label class="required">Контактное лицо</label>
          <ECInput
            placeholder="Введите свое имя"
            v-model="name"
            maxLength="127"
          />
        </div>

        <div v-if="!isAuthorized" class="form-item">
          <label class="required">Телефон</label>
          <ECInput
            placeholder="+7"
            masked="tel"
            v-model="phone"
          />
        </div>

        <div v-if="!isAuthorized" class="form-item small-margin">
          <label class="required">Территория</label>
          <ECSelect
            :items="territoryItems"
            @changeSelect="territoryItemSelected = $event"
            :selectedItem="territoryItemSelected"
            :maxHeight="225"
          />
        </div>

        <div v-if="!isAuthorized" class="form-item is-ul">
          <ECCheckbox
            label="Я - юридическое лицо"
            :checked="isUL"
            @clicked="changeIsUL"
          />
        </div>



        <transition name="bottom">
          <div v-if="isUL" class="form-item">
          <label class="required">ИНН</label>
          <ECInput
            v-model="inn"
            masked="inn"
            placeholder="Введите 10-12 цифр"
            :error="INNerror"
          />

          <transition name="default">
            <div class="dadata" v-if="daDataArr.length && isShowDaDataMenu">
              <div class="dadata-item"
                v-for="item in daDataArr"
                :key="item.inn"
                @click="setDaData(item)"
              >
                <span>{{item.label}}</span>
                <span>ИНН {{item.inn}} <template v-if="item.kpp">/ КПП {{item.kpp}}</template></span>
              </div>
            </div>
          </transition>
        </div>
      </transition>

        <div class="form-item">
          <label class="required">Электронный адрес</label>
          <ECInput
            placeholder="client@mail.ru"
            v-model="email"
          />
        </div>

        <div v-if="isAuthorized" class="form-item">
          <label>Уточните лицевой счет для обращения</label>
          <ECSelect
            :items="selectItems"
            @changeSelect="selectItemSelected = $event"
            :selectedItem="selectItemSelected"
            clearValue
            :emptyMessage="!selectItems.length ? 'Вы еще не добавили ни один ЛС' : ''"
          />
          <svg v-if="selectItemSelected.id != ''" @click="selectItemSelected = {id: '', label: 'Не выбрано'}" class="clear-value" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6569 0.343378C12.0474 0.733902 12.0474 1.36707 11.6569 1.75759L7.41415 6.0003L11.6568 10.2429C12.0473 10.6335 12.0473 11.2666 11.6568 11.6572C11.2663 12.0477 10.6331 12.0477 10.2426 11.6572L5.99993 7.41451L1.75736 11.6571C1.36684 12.0476 0.73367 12.0476 0.343146 11.6571C-0.0473788 11.2666 -0.0473781 10.6334 0.343146 10.2429L4.58572 6.0003L0.343078 1.75766C-0.0474466 1.36713 -0.0474455 0.733969 0.343078 0.343445C0.733603 -0.0470792 1.36677 -0.0470789 1.75729 0.343445L5.99993 4.58609L10.2426 0.343378C10.6332 -0.0471467 11.2663 -0.047147 11.6569 0.343378Z" fill="#C6C6C6"/>
          </svg>
        </div>


        <div class="form-item form-item--textarea">
          <label class="required">Ваш вопрос</label>
          <ECTextarea placeholder="Опишите ваш вопрос                                   (минимум 10 символов)" v-model="message"/>
        </div>

        <transition name="bottom">
          <div v-if="fileError" class="max-size-file">{{fileError}}</div>
        </transition>

        <div class="form-item" v-if="files.length">
          <div
            class="uploaded-file"
            v-for="(file, idx) in files"
            :key="idx"
          >
            <img src="@/assets/images/success-uploaded-file.svg"/>
            <span>{{file.name}}</span>
            <img @click="removeFile(idx)" src="@/assets/images/remove-file.svg"/>
          </div>
        </div>

        <div class="form-item form-item--attach">
          <ECButton
            label="Прикрепить файл"
            view="secondary"
            @click="$refs.fileInput.click()"
            :disabled="files.length == 5"
          />
        </div>

        <div class="form-item send" v-if="!forceDisabled">
          <ECButton
            label="Отправить"
            view="primary"
            :disabled="isAuthorized ? !validateAuthorized : !validateNonAuthorized"
            @click="sendForm"
          />
        </div>

        <div class="form-item or">или</div>

        <div class="form-item form-item--call">
          <a href="tel:+73472980490" class="button-link">+7 (347) 298 04 90</a>
        </div>

        <input ref="fileInput" type="file" multiple v-on:change="handleFileUpload(e)" />
      </form>
    </transition>

    <transition name="quick-hide">
      <ECSuccessSendedRequest
        v-if="successSended"
        :id="successSended"
      />
    </transition>
  </ECModal>
</template>

<script setup>
import { defineEmits, ref, computed, onMounted, watchEffect } from 'vue'
import store from '@/store'
import axios from 'axios'
import useApi from '@/services/useApi'
import formattedPhone from '@/helpers/formattedPhone.js'
import ECSuccessSendedRequest from '@/components/common/ECSuccessSendedRequest.vue'

const selectItemSelected = ref({
  id: '',
  label: 'Не выбрано'
})

const selectItems = ref([])

onMounted(async()=> {
  if(store.state.user.token){
    const req = await useApi('get', 'bills')

    selectItems.value = req.bills.map(item => {
      return {
        id: item.number,
        label: item.number
      }
    })
  }
  getDistricts()
})

const getDistricts = async() => {
  // const req = await axios.get(`${process.env.VUE_APP_STRAPI_API}/districts`)
  const req = await axios.get(`https://admin.roecocity.ru/api/districts?pagination[pageSize]=1000000`)

  territoryItems.value = req.data.data.map(item => {
    return {
      id: item.attributes.tax_code,
      label: item.attributes.name,
    }
  })
  territoryItems.value.sort(( a, b ) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1)
}

const INNerror = ref('')

const successSended = ref('')

const isAuthorized = computed(()=> store.state.user.token ? true : false)

const fileInput = ref(null)

const name = ref('')
const phone = ref('')
const isUL = ref(false)
const inn = ref('')
const email = ref('')
const message = ref('')
const kpp = ref('')
const dadataLabel = ref('')




const forceDisabled = ref(false)


const territoryItems = ref([])

const territoryItemSelected = ref({
  id: '',
  label: 'Не выбрано'
})

const changeIsUL = () => {
  isUL.value = !isUL.value
  inn.value = ''
}

const validateAuthorized = computed(()=>
  email.value.includes('@') &&
  email.value.includes('.') &&
  message.value.length >= 10
)

const validateNonAuthorized = computed(()=>
  name.value.length >= 2 &&
  phone.value.length === 16 &&
  territoryItemSelected.value.id.length &&
  email.value.includes('@') &&
  email.value.includes('.') &&
  message.value.length >= 10 &&
  (!isUL.value || ((inn.value.length === 10 || inn.value.length === 12) && isValideInn.value) )
)


const files = ref([])
const fileError = ref('')

const handleFileUpload = () => {
  fileError.value = ''

  if(fileInput.value.files.length > 5){
    fileError.value = 'Вы можете загрузить максимум 5 файлов'
    return
  }

  for (let i = 0; i < fileInput.value.files.length; i++) {
    if(fileInput.value.files[i].size >= 10000000){
      fileError.value = 'Вы не можете загружать файлы больше 10мб'
      return
    }
  }

  if(fileError.value === ''){
    for (let i = 0; i < fileInput.value.files.length; i++) {
      files.value.push(fileInput.value.files[i])
    }
  }
}

const removeFile = idx => {
  files.value.splice(idx, 1)
}

const sendForm = () => {
  const formData = new FormData()

  forceDisabled.value = true

  if(isAuthorized.value){
    formData.append('bill_number', selectItemSelected.value.id)
  } else {
    formData.append('name', name.value)
    formData.append('phone', formattedPhone(phone.value))
    formData.append('inn', inn.value)
    formData.append('kpp', kpp.value)
    formData.append('label', dadataLabel.value)
    formData.append('tax_code', territoryItemSelected.value.id)
  }

  formData.append('email', email.value)
  formData.append('content', message.value)

  for (let i = 0; i < files.value.length; i++) {
    formData.append('files', files.value[i])
  }

  axios.post(`${process.env.VUE_APP_BASE_API}/requests/create/${isAuthorized.value ? '' : 'non-'}auth`,
    formData,
    {
      headers: {
        'Authorization': store.state.user.token,
        'Content-Type': 'multipart/form-data'
      }
    }
  )
  .then((res)=> {
    if(res.data.request_id){
      successSended.value = res.data.request_id
    }
  })
}

const daDataArr = ref([])
const isShowDaDataMenu = ref(false)
const isValideInn = ref(false)

const setDaData = (x) => {
  kpp.value = x.kpp
  dadataLabel.value = x.label
  inn.value = x.inn
  isValideInn.value = true
  isShowDaDataMenu.value = false
}

watchEffect(async() => {
  isValideInn.value = false
  if(inn.value.length === 10 || inn.value.length === 12){

    const url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party"
    const token = "f94a30447c5eaf823177a205adbcd6df33603ba9"
    const options = {
      method: "POST",
      mode: "cors",
      headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": "Token " + token
      },
      body: JSON.stringify({
        query: inn.value,
        count: 300
      })
    }
    const data = await fetch(url, options)
    const dd = await data.json()

    if(dd.suggestions.length){
      daDataArr.value = dd.suggestions.map(item => {
        return {
          label: item.value,
          inn: item.data.inn,
          kpp: item.data.kpp || '',
        }
      })
      isShowDaDataMenu.value = true

      INNerror.value = ''
    } else {
      INNerror.value = 'Такого ИНН не найдено'
    }
  } else {
    daDataArr.value = []
  }
})
</script>

<style scoped lang="scss">
.is-ul{
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 30px;
}

input[type="file"]{
  display: none;
}

.button-link{
  background: #ECECEC;
  color: #161616;
  border-radius: 16px;
  padding: 14.5px 20px;
  font-size: 18px;
  line-height: 19px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: ease 0.3s;
  &:active{
    background-color: #EEEEEE;
  }
  &:hover{
    background-color: #D1D1D1;
  }
}

.or{
  text-align: center;
  opacity: .5;
  font-size: 16px;
}

.uploaded-file{
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span{
    overflow: hidden;
    white-space: nowrap;
    padding: 0 10px;
    text-overflow: ellipsis;
    font-size: 16px;
    line-height: 22px;
    width: -webkit-fill-available;
  }
  img{
    &:last-child{
      cursor: pointer;
    }
  }
}

.max-size-file{
  color: $ec-red;
  font-size: 14px;
  text-align: center;
  margin-bottom: 10px;
}

.succes-sended{
  display: flex;
  align-items: center;
  flex-direction: column;
  img{
    margin-bottom: 20px;
  }
  .title{
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.01em;
    margin-bottom: 17px;

    &--sec{
      margin-bottom: 12px;
    }
  }
  .grey{
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: rgba(22, 22, 22, 0.5);
    margin-bottom: 10px;
    text-align: center;

    &--sec{
      padding-bottom: 20px;
    }
  }
}
.clear-value{
  position: absolute;
  right: 35px;
  bottom: 13px;
  cursor: pointer;
}

.form-item--textarea{
  margin-bottom: 14px;
}
.form-item--attach{
  margin-bottom: 10px;
}
.form-item--call{
  margin-bottom: 0px;
}
</style>
