<template>
  <button
    class="ec-button"
    :class="`ec-button--${view}`"
    @click="onClick"
    :disabled="disabled"
  >
    <slot name="iconLeft" />
    {{ label }}
    <slot name="iconRight" />
  </button>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue'

const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  view: {
    type: String,
    default: 'primary',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits(['clicked'])

const onClick = () => {
  if (!props.disabled){
    emit('clicked')
  }
}
</script>

<style scoped lang="scss">
.ec-button {
  border-radius: 16px;
  padding: 13px 20px;
  font-size: 18px;
  line-height: 22px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  &--primary {
    background-color: $ec-green;
    &:active{
      background-color: linear-gradient(0deg, #49AE50, #49AE50), #424242;
    }
    &:hover{
      background-color: #64CC6C;
    }
    &:disabled{
      background-color: rgba(87, 186, 94, 0.5);
      pointer-events: none;
    }
  }
  &--secondary{
    background-color: $ford-medium-dark;
    &:active{
      background-color: #2E2E2E
    }
    &:hover{
      background-color: #353535
    }
    &:disabled{
      opacity: .5;
      pointer-events: none;
    }
  }
  &--transparent{
    background: transparent;
    color: $ec-green;
  }
  &--white{
    background: white;
    color: $primary-font-color;
    &:active{
      background-color: #E3E3E3
    }
    &:hover{
      background-color: #ffffff82;
    }
    &:disabled{
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), #424242;
      pointer-events: none;
    }
  }
  &--grey{
    background:#ECECEC;
    color: $primary-font-color;
    &:active{
      background-color: #EEEEEE;
    }
    &:hover{
      background-color: #D1D1D1;
    }
    &:disabled{
      background: rgba(236, 236, 236, 0.5);
      pointer-events: none;
    }
  }
  &--link{
    margin-top: 7px;
    background: transparent;
    color: #57BA5E;
    &:active{
      background-color: transparent;
      color: #49AE50;
    }
    &:hover{
      background-color: transparent;
      color: #64CC6C;
    }
    &:disabled{
      background: transparent;
      opacity: .5;
      pointer-events: none;
      color: rgba(87, 186, 94, 0.5);
    }
  }
}
</style>
